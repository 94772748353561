import React, {useState, useEffect, useContext} from 'react'

import {GlobalContext} from "../../../../ctx";

import SendEmail from './SendEmail'
import ChangePass from './ChangePass'

const Recover = ({ }) => {

    const [global, setGlobal] = useContext(GlobalContext)
    const token = global && global.recoverToken

    const [isChange, setIsChange] = useState(false) //true
   
    useEffect(() => {
        setIsChange(!!token)
    }, [token])

    const Specified = isChange ? ChangePass : SendEmail;

    return <Specified token={token}/>
}

export default Recover
import React, { useState, useEffect } from 'react'

import { Dropdown } from 'primereact/dropdown'

import { Skeleton } from 'primereact/skeleton'
import { Loader } from '../Atoms'
import axios from 'axios'
import { ENDPOINT } from '../../env'

import CustomScrollbars from 'react-custom-scrollbars-2'

import Standings from './index'

import './multi-wrap.scss'

import { CONF } from '../../configs'
import {SelectButton} from "primereact/selectbutton";

const SBProvider = ({ children, layout }) => {
    return layout === 'mobile' ? children : (
        <CustomScrollbars autoHeight autoHeightMin={730} autoHide>
            {children}
        </CustomScrollbars>
    )
}

const MultiWrap = ({ theme, config, width, layout, stashed, pathname }) => {
    const [tournamentIdx, setTournamentIdx] = useState(0)
    const [data, setData] = useState(null)
    const [stage, setStage] = useState(0);

    const federation = JSON.parse(localStorage.getItem('_am_federation'))

    useEffect(() => {
        if(!stashed) {
            axios.get(`${ENDPOINT}client/tournaments_navi/${federation._id}`)
                .then(resp => {
                    setData(resp.data)
                })
        }
    }, [])

    useEffect(() => {
        if(data && data[tournamentIdx] && !data[tournamentIdx].standings && !data[tournamentIdx].groups) {
            axios.get(`${ENDPOINT}client/standings/${data[tournamentIdx]._id}`)
                .then(resp => {
                    if(resp.data && resp.data[0]) {
                        setData(data.map((t, ix) => ix === tournamentIdx ? {...t, groups: resp.data[0].groups || null, standings: resp.data[0].standings || null, stages: resp.data.length > 1 ? resp.data.filter(rd => rd.type !== 'playoff') : null} : t))
                    } else {
                        setData([])
                    }
                })
        }
    }, [tournamentIdx, data])

    useEffect(() => {
        setStage(0)
        if(data && data[tournamentIdx] && data[tournamentIdx].stages) {
            setData(data.map(
                (t, ix) => ix === tournamentIdx ? {
                    ...t,
                    groups: data[tournamentIdx].stages[0].groups || null,
                    standings: data[tournamentIdx].stages[0].standings || null
                } : t)
            )
        }
    }, [tournamentIdx])

    return  <div className='multi-standings'>
                <div className='selector'>
                    {!data ? (
                        <Skeleton height='34px' />
                    ) : [
                        <Dropdown
                            value={tournamentIdx}
                            options={data.map((t, ix) => ({value: ix, label: t.name}))}
                            onChange={e => {
                                setTournamentIdx(e.target.value)
                            }}
                        />,
                        //селектор стадий, если они имееются
                        data[tournamentIdx] && data[tournamentIdx].stages && data[tournamentIdx].stages.length > 1 ?
                            <SelectButton
                                value={stage}
                                options={data[tournamentIdx].stages.map((di, ix) => ({value: ix, label: di.title}))}
                                onChange={(e) => {
                                    setStage(e.value)
                                    setData(data.map((t, ix) => ix === tournamentIdx ? {...t, groups: data[tournamentIdx].stages[e.value].groups || null, standings: data[tournamentIdx].stages[e.value].standings || null} : t))
                                }}
                                optionLabel="label"
                                unselectable={false}
                            /> : null
                    ]}
                </div>

                <SBProvider layout={layout}>
                    <div className='content-body'>
                        {!data || !data[tournamentIdx] || (!data[tournamentIdx].standings && !data[tournamentIdx].groups) ? (
                            <Loader width={200} />
                        ) : data[tournamentIdx].groups ? data[tournamentIdx].groups.map(g => (
                            <Standings
                                data={g.standings}
                                layout={layout}
                                config={config}
                                theme={theme}
                                title={g.name || g.title}
                                pathname={pathname}
                            />
                        )) : (
                            <Standings
                                data={data[tournamentIdx].standings}
                                layout={layout}
                                config={config}
                                theme={theme}
                                pathname={pathname}
                            />
                        )}
                    </div>
                </SBProvider>
            </div>
}

export default MultiWrap

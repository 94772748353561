import React, {useState, useEffect, useContext} from 'react'

import {GlobalContext} from "../../../../ctx";

import { getByUrl } from "../../../../v3MethodsService";

import Success from './Success';
import Error from './Error';

const Reg = ({ }) => {
    const [global, setGlobal] = useContext(GlobalContext)

    const token = global && global.regToken

    const [loading, setLoading] = useState(true)
    const [isError, setIsError] = useState(true)
    const [tokenData, setTokenData] = useState(null)
    const [step, setStep] = useState('email') //email, personData
   
    useEffect(() => {
        if (token) {
            setLoading(true)
            getByUrl(`clientAuth/check_verify?verify=${token}`)
                .then(resp => {
                    if (resp) {
                        setIsError(!resp.success)
                        setTokenData({...resp.data, token: token})
                        setStep('personData')
                    }
                    setLoading(false)
                })
        } else {
            setIsError(false)
            setLoading(false)
        }
    }, [token])

    const Specified = isError ? Error : Success;

    return loading ? <div className='auth2__container'></div> : <Specified tokenData={tokenData} step={step} setStep={setStep}/>
}

export default Reg
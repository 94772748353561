import React, {useContext, useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import moment from "moment/moment";
import { diff } from 'deep-object-diff';
import {GlobalContext} from "../../../ctx";
import {getByUrl, postByUrl} from "../../../v3MethodsService";
import {ADMINENDPOINT} from "../../../env";

import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { SelectButton } from "primereact/selectbutton";
import {Toast} from "primereact/toast";

import SideProfileEditor from "./Editor";
import Slider from "./Slider";
import Auth2 from "../Auth2";
import BindedPlayer from "./BindedPlayer";
import ImageWithFallback from "../Common/ImageWithFallback";

import team from "./img/team.png";
import logo from "./img/logo.svg";
import achieve from "./img/achieve.png";
import streak from "./img/streak.png";
import streak2 from "./img/streak2.png";
import streak3 from "./img/streak3.png";
import streak4 from "./img/streak4.png";
import noImage from './img/noImage.svg'
import { useClickOutside } from "../../../utils";

import "./style.scss";

const SideProfile = ({ visible = false, onHide = () => {}, target = "", data = null }) => {
    const [global, setGlobal] = useContext(GlobalContext)
    const toast = useRef(null)
    const history = useHistory()

    const ref = useRef();

    useClickOutside(visible, ref, onHide)

    const [state, setState] = useState(data);
    const [changableState, setChangableState] = useState(null)
    const [isEditor, setIsEditor] = useState(false);

    const [select, setSelect] = useState(options[0]);
    const [selectedTab, setSelectedTab] = useState("");

    const [favorite, setFavorite] = useState("player");
    const [favoritesData, setFavoritesData] = useState({players: [], teams: []})

    const [isValid, setIsValid] = useState(false);
    const [showInvalid, setShowInvalid] = useState(false);

    const [userAchievements, setUserAchievements] = useState([])
    const [bindedPlayer, setBindedPlayer] = useState(null)

    useEffect(() => {
        let body = document.body
        if (body && visible) {
            // body.style.position = 'fixed';
            // body.style.top = '0px';

            body.style.overflow = 'hidden'
            body.style['touch-action'] = 'none'
            body.style['-webkit-overflow-scrolling'] = 'none'
            body.style['overscroll-behavior'] = 'none'

            body.style.width = '100%'
        } else {
            body.style = {}
        }
    }, [visible])

    useEffect(() => {
        setState(data)
        setChangableState(data)
        if (data && !(userAchievements && userAchievements.length > 0)) {
            if (!data.bindedPlayerId) {
                setSelect(options[2])
            } else {
                getByUrl(`client/achievements/${data.bindedPlayerId}`)
                    .then(resp => {
                        if (resp) {
                            setUserAchievements(resp)
                        }
                    })
            }
        }
    }, [data]);


    useEffect(() => {
        if (state && !state.bindedPlayerId && !bindedPlayer) {
            setSelect(options[2])
            getByUrl('clientAuth/bindsuggestions')
                .then(resp => {
                    if (resp && resp.success) {
                        setBindedPlayer(resp.data[0])
                    }

                })
            
        }
        else {
            setSelect(options[0])

        }
        
    }, [state]);

    useEffect(() => {
        if (state && state.favorites) {
            const playersData = state.favorites.filter(f => f.type === 'player')
            const teamsData = state.favorites.filter(f => f.type === 'team')

            if (playersData.length === 0) {
                setFavorite('team')
            }
            if (teamsData.length === 0) {
                setFavorite('player')
            }

            setFavoritesData({players: playersData, teams: teamsData})
        }
    }, [state]);

    useEffect(() => {
        if (changableState) {
            const notValid = formData.map((f) => (!changableState.profile[f.key] && !changableState.creds[f.key] && !changableState[f.key]) && !['password', 'repeat', 'phone'].includes(f.key)).filter((f) => f);
            setIsValid(!notValid.length);
        } else {
            setIsValid(false);
        }
    }, [changableState]);

    const changeTabHandler = (id) => {
        setSelectedTab(selectedTab === id ? "" : id);
    };

    const saveHandler = async () => {
        if (isValid) {
            const isEmailChanged = changableState.creds.email !== state.creds.email
            const isPasswordChanged = changableState.password && changableState.repeat
            let obj = {profile: changableState.profile}
            if (isPasswordChanged) {
                if (changableState.password === changableState.repeat) {
                    if (isValidPassword(changableState.password)) {
                        obj.creds = {...changableState.creds, password: changableState.password}
                    } else {
                        toast.current.show({ severity: "error", life: 4000, summary: "Пароль должен быть: мин. 8 симв., латиница, мин. 1 буква, мин. 1 цифра" });
                        return;
                    }
                } else {
                    toast.current.show({ severity: "error", life: 4000, summary: "Пароли не совпадают" });
                    return
                }
            }
            const respChangeProfile = await postByUrl('clientAuth/changeProfile', obj)
            if (respChangeProfile && respChangeProfile.success) {
                setIsEditor(false)
                toast.current.show({severity: 'success', summary: 'Изменения сохранены', life: 1000})
                setGlobal(prev => ({
                    ...prev,
                    account: {
                        ...prev.account,
                        profile: changableState.profile
                    }
                }))
            }
            if (isEmailChanged) {
                confirmDialog({
                    message: <>Вы получите письмо с ссылкой для подтверждения нового адреса электронной почты.<br/>Обратите внимание, что вы потеряете доступ к аккаунту, пока не подтвердите новый адрес.</>,
                    header: 'Вы уверены, что хотите сменить почту?',
                    icon: 'pi pi-exclamation-triangle',
                    acceptClassName: 'p-button-danger',
                    acceptLabel: 'Да',
                    rejectLabel: 'Нет',
                    draggable: false,
                    appendTo: "self",
                    accept: async () => {
                        const respChangeEmail = await postByUrl('clientAuth/changeEmail', {email: changableState.creds.email})
                        if (respChangeEmail && respChangeEmail.success) {
                            // navigator.clipboard.writeText(respChangeEmail.token) only dev !!!!!!
                            navigator.clipboard.writeText(respChangeEmail.token).then(r => {
                                getByUrl('clientAuth/init')
                                    .then(resp => {
                                        if (resp && resp.success) {
                                            setGlobal(prev => ({...prev, account: resp.data ? {...resp.data, hasOtherVerifyAccount: resp.hasOtherVerifyAccount} : false}))
                                        }
                                    })
                            })
                        } else {
                            toast.current.show({
                                severity: "error",
                                summary: respChangeEmail?.message || "Ошибка сервера. Повторите попытку позже.",
                                life: 1000
                            });
                        }
                    },
                    reject: () => {},
                });
            }
        } else {
            setShowInvalid(true);
            toast.current.show({severity: "error", summary: "Заполните все обязательные поля", life: 1000});
        }
    };

    const changeState = (val, key, subkey) => {
        if (subkey) {
            setChangableState((prev) => ({ ...prev, [key]: {...prev[key], [subkey]: val} }));
            return
        }
        setChangableState((prev) => ({ ...prev, [key]: val }));
    };

    const unsubscribeHandler = (_id) => {
        const newFavorites = [...state.favorites].filter(f => f._id !== _id)
        postByUrl('clientAuth/changeProfile', {favorites: newFavorites})
            .then(_ => {
                setGlobal(prev => ({...prev, account: {...prev.account, favorites: newFavorites}}))
            })
    }

    const renderOptions = () => {
        switch (select) {
            case "Личное":
                return (
                    <>
                        {!!(state.nextMatches && state.nextMatches.length) && (
                            <div id="matches"
                                 className={`side-profile__accordion${selectedTab === "matches" ? " side-profile__accordion_active" : ""}`}>
                                <div className="side-profile__accordion-header">
                                    <div className="side-profile__accordion-title">Следующие матчи</div>
                                    <i className={`${selectedTab === "matches" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("matches")}/>
                                </div>
                                <div className="side-profile__accordion-role">
                                    <Slider data={state.nextMatches} type="match"/>
                                    <div className="side-profile__accordion-btns">
                                        <i className="pi pi-clone"/>
                                        <i className="pi pi-list"/>
                                    </div>
                                </div>
                            </div>
                        )}
                        {userAchievements?.length > 0 && (
                            <div id="achieve" className={`side-profile__accordion${selectedTab === "achieve" ? " side-profile__accordion_active" : ""}`}>
                                <div className="side-profile__accordion-header">
                                    <div className="side-profile__accordion-title">Достижения</div>
                                    <i className={`${selectedTab === "achieve" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("achieve")}/>
                                </div>
                                <div className="side-profile__accordion-role">
                                    <Slider data={userAchievements} type="achieve"/>
                                </div>
                            </div>
                        )}
                    </>
                );
            case "Клубное":
                return (
                    <>
                        <div id="teams"
                             className={`side-profile__accordion${selectedTab === "teams" ? " side-profile__accordion_active" : ""}`}>
                            <div className="side-profile__accordion-header">
                                <div className="side-profile__accordion-title">Ваши команды</div>
                                <i className={`${selectedTab === "teams" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`}
                                   onClick={() => changeTabHandler("teams")}/>
                            </div>
                            <div className="side-profile__accordion-role">
                                <Slider data={splitObject(state.allTeams)} type="teams" onHide={onHide}/>
                            </div>
                        </div>
                        <div id="streak" className={`side-profile__accordion${selectedTab === "streak" ? " side-profile__accordion_active" : ""}`}>
                            <div className="side-profile__accordion-header">
                                <div className="side-profile__accordion-title">Командные серии</div>
                                <i className={`${selectedTab === "streak" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("streak")} />
                            </div>
                            <div className="side-profile__accordion-role">
                                <div className="side-profile__streak">
                                    {state.series.map((item, idx) => series.map(s => !!item[s.key] && (
                                        <div key={idx + s.key} className="side-profile__streak-item">
                                            <div className="side-profile__streak-top">{item[s.key].title}</div>
                                            <img src={s.logo} className="side-profile__streak-logo"/>
                                            <div className="side-profile__streak-container">    
                                                <span className="side-profile__streak-text">зафиксирована:</span>
                                                <span className="side-profile__streak-date">{moment(item[s.key].established, 'YY-MM-DD').format('DD MMMM YYYY')}</span>
                                            </div>
                                            <div className="side-profile__streak-score">
                                                <span className="side-profile__streak-count">{item[s.key].value}</span>
                                                <span className="side-profile__streak-matches">матчей</span>
                                            </div>
                                        </div>
                                    )))}
                                </div>
                            </div>
                        </div>
                    </>
                );
            case "Избранное":
                return (
                    <>
                        <div id="favorite"
                             className={`side-profile__accordion${selectedTab === "favorite" ? " side-profile__accordion_active" : ""}`}>
                            <div className="side-profile__accordion-header">
                                <div className="side-profile__accordion-title">Избранное</div>
                                <i className={`${selectedTab === "favorite" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`}
                                   onClick={() => changeTabHandler("favorite")}/>
                            </div>
                            <div className="side-profile__accordion-role">
                                {favoritesData.players.length || favoritesData.teams.length ? (
                                    <>
                                        <Slider data={splitObject(favorite === "player" ? favoritesData.players : favoritesData.teams)} type="favorite" unsubscribeHandler={unsubscribeHandler} onHide={onHide}/>
                                        <div className="side-profile__accordion-btns">
                                            {favoritesData.players.length > 0 && (
                                                <div className={`side-profile__accordion-btn ${favorite === "player" ? "active" : ""}`} onClick={() => setFavorite("player")}>
                                                    <i className="pi pi-user"></i>
                                                    <span>игрок</span>
                                                </div>
                                            )}
                                            {favoritesData.teams.length > 0 && (
                                                <div className={`side-profile__accordion-btn ${favorite === "team" ? "active" : ""}`} onClick={() => setFavorite("team")}>
                                                    <i className="pi pi-shield"></i>
                                                    <span>команда</span>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <span className="side-profile__accordion-none">У Вас еще нет избранных страниц</span>
                                )}
                            </div>
                        </div>
                    </>
                );

            default:
                break;
        }
    };

    const exitHandler = () => {
        confirmDialog({
            message: 'Вы уверены, что хотите выйти из аккаунта?',
            header: 'Выйти из аккаунта',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Да',
            rejectLabel: 'Нет',
            draggable: false,
            appendTo: "self",
            accept: () => {
                setGlobal(prev => ({...prev, token: null, account: null}))
                localStorage.removeItem('_amateum_tkn')
            },
            reject: () => {},
        });
    }

    const getUserInfo = () => {
        getByUrl('clientAuth/init')
            .then(resp => {
                if (resp && resp.success) {
                    setGlobal(prev => ({...prev, account: resp.data ? {...resp.data, hasOtherVerifyAccount: resp.hasOtherVerifyAccount} : false}))
                }
            })
    }

    const diffData = (data, state) => {
      
        const prepareData = (obj) => {
          if(obj) {
            return Object.keys(obj)?.reduce((acc, row) => {
              if (Array.isArray(obj[row]) && obj[row].some((s) => s._id)) {
                //если масиив, то приводим к нужному виду
                acc[row] = obj[row].map((it) => it._id);
              } else if (
                typeof obj[row] === 'string' &&
                obj[row].match(/(\d+)-(\d+)-(\d+)\w(\d+):(\d+):(\d+)/gim)
              ) {
                //если дата, то приводим к new Date
                acc[row] = new Date(obj[row]);
              } else {
                acc[row] = obj[row];
              }
        
            return acc;
            }, {})
          }
        }
      
        const newState = prepareData({...state.profile, email: state.creds.email });
        const newData = prepareData({...data.profile, email: data.creds.email });

      
        const obj = diff(newData, newState);

        const arr = obj && Object.keys(obj);
        const resp = {};
      
        if (arr?.length) {
          arr.forEach((item) => {
            if (obj[item] !== undefined) {
              resp[item] = newState[item];
            }
          });
        }

        const isPasswordChanged = changableState.password && changableState.repeat
      
        return Object.keys(resp).length ? resp : !!isPasswordChanged;
    };

    const lkHandler = () => {
        window.open(`${ADMINENDPOINT}`, '_blank')
    }

    return (
        !!visible && (
            <div className="side-profile__overlay p-sidebar-mask p-component-overlay p-component-overlay-enter p-sidebar-visible p-sidebar-right">
                <Toast ref={toast} position="top-right"/>
                <div className="side-profile p-sidebar p-component p-sidebar-enter-done" ref={ref}>
                    <ConfirmDialog appendTo="self"/> 
                    {state ? [
                        <div key='side-profile__header' className="side-profile__header">
                            {isEditor ? (
                                <div className="side-profile__header-btn-group">
                                    <i className="side-profile__icon-close pi pi-chevron-left" onClick={() => setIsEditor(false)} />
                                    <Button className="side-profile__button-save side-profile__button side-profile__button_outlined" disabled={!diffData(state, changableState)} label="Сохранить" onClick={saveHandler} />
                                </div>
                            ) : (
                                <div className="side-profile__header-btn-group">
                                    <Button className={`side-profile__button-block side-profile__button side-profile__button_outlined`} label={`Выйти`} onClick={exitHandler}/>
                                    {!!(global?.account?.hasOtherVerifyAccount) && <Button className='side-profile__button-save auth2__button_outlined' label='ЛК панели управления' onClick={lkHandler}/>}
                                </div>
                            )}
                            <i className="side-profile__icon-close pi pi-times" onClick={onHide} />
                        </div>,
                        isEditor ? (
                                <SideProfileEditor key='SideProfileEditor' state={{...changableState.profile, email: changableState.creds.email, password: changableState.password, repeat: changableState.repeat }} changeState={changeState} formData={formData} showInvalid={showInvalid} />
                            ) : (
                                <div key="side-profile__body" className="side-profile__body">
                                    <div className="side-profile__account">
                                        <div className="side-profile__avatar">
                                            {state.bindedPlayer && state.bindedPlayer.avatarUrl ? <ImageWithFallback src={state.bindedPlayer && state.bindedPlayer.avatarUrl} alt="avatar" fallback={<img src={noImage}/>}/> : <img src={noImage}/>}
                                        </div>
                                        <div className="side-profile__account-info">
                                            <div className="side-profile__account-text">
                                                <div className="side-profile__role">{state.bindedPlayerId ? 'Футболист' : 'Болельщик'}</div>
                                                <div className="side-profile__account-main">
                                                    {state.profile.surname + " " + state.profile.name + " " + state.profile.middlename}
                                                    {/* <Tag value='верифицирован' icon="pi pi-check" severity='success' className='side-profile__tag'/> */}
                                                </div>
                                                <div className="side-profile__account-bday">
                                                    Дата рождения
                                                    <span>{state.profile.birthday}</span>
                                                </div>
                                                {!!state.bindedPlayerId && (
                                                    <div className="side-profile__profile-link" onClick={() => {history.push(`/player/${state.bindedPlayer._id}`); onHide()}}>
                                                        Перейти на страницу
                                                        <i className="pi pi-angle-right"/>
                                                    </div>
                                                )}
                                            </div>
                                            <i className="side-profile__account-edit pi pi-pencil" onClick={() => setIsEditor(true)}/>
                                        </div>
                                    </div>
                                    {/*<Slider data={newsData} type="news" />*/}
                                    {!!bindedPlayer && <BindedPlayer state={bindedPlayer} getUserInfo={getUserInfo} notPlayerIds={state.notPlayerIds || []} clearBindedPlayer={() => setBindedPlayer(null)}/>}
                                    {!!state.bindedPlayerId && (
                                        <SelectButton
                                            value={select}
                                            onChange={(e) => {
                                                if (e.value) {
                                                    setSelect(e.value);
                                                }
                                            }}
                                            options={options}
                                        />
                                    )}
                                    {!bindedPlayer && renderOptions()}
                                </div>
                            )
                    ] : (
                        <Auth2 onHide={onHide}/>
                    )}
                </div>
            </div>
        )
    );
};

const options = ["Личное", "Клубное", "Избранное"];

const splitObject = (array) => {
    let result = [];
    for (let i = 0; i < array.length; i += 4) {
        result.push(array.slice(i, i + 4));
    }
    return result;
};

const formData = [
    { key: "surname", label: "Фамилия*", placeholder: "Укажите фамилию", type: "input", size: "full" },
    { key: "name", label: "Имя*", placeholder: "Укажите имя", type: "input", size: "full" },
    { key: "middlename", label: "Отчество*", placeholder: "Укажите отчество", type: "input", size: "full" },
    { key: "birthday", label: "Дата рождения*", placeholder: "Укажите дату рождения", type: "calendar", size: "full" },
    { key: "email", label: "Email*", placeholder: "Укажите email", type: "input", size: "full" },
    { key: "phone", label: "Номер телефона", placeholder: "+ 7__________", type: "mask", size: "full", mask: '+79999999999' },
    // { key: "password", label: "Пароль", placeholder: "Придумайте пароль", type: "password", size: "full", icon: "pi pi-info-circle" },
    // { key: "repeat", label: "Подтверждение пароля", placeholder: "Повторите пароль", type: "password", size: "full" },
];

const newsData = [
    { img: logo, title: "Новости платформы", subtitle: "Мы обновили движок видеотрансляций" },
    { img: logo, title: "Новости платформы", subtitle: "Мы обновили движок видеотрансляций" },
    { img: logo, title: "Новости платформы", subtitle: "Мы обновили движок видеотрансляций" },
    { img: logo, title: "Новости платформы", subtitle: "Мы обновили движок видеотрансляций" },
    { img: logo, title: "Новости платформы", subtitle: "Мы обновили движок видеотрансляций" },
    { img: logo, title: "Новости платформы", subtitle: "Мы обновили движок видеотрансляций" },
    { img: logo, title: "Новости платформы", subtitle: "Мы обновили движок видеотрансляций" },
    { img: logo, title: "Новости платформы", subtitle: "Мы обновили движок видеотрансляций" },
];

const matchData = [
    { teamA: { logo: team, name: "Алгоритм", score: 4 }, teamB: { logo: team, name: "Крысиная Нора", score: 0 }, place: "Политехник. Поле 1", time: "10:00", date: "16 июня", league: "Суперлига" },
    { teamA: { logo: team, name: "Алгоритм", score: 4 }, teamB: { logo: team, name: "Крысиная Нора", score: 0 }, place: "Политехник. Поле 1", time: "10:00", date: "16 июня", league: "Суперлига" },
    { teamA: { logo: team, name: "Алгоритм", score: 4 }, teamB: { logo: team, name: "Крысиная Нора", score: 0 }, place: "Политехник. Поле 1", time: "10:00", date: "16 июня", league: "Суперлига" },
    { teamA: { logo: team, name: "Алгоритм1111", score: 4 }, teamB: { logo: team, name: "Крысиная Нора", score: 0 }, place: "Политехник. Поле 1", time: "10:00", date: "16 июня", league: "Суперлига" },
    { teamA: { logo: team, name: "Алгоритм", score: 4 }, teamB: { logo: team, name: "Крысиная Нора", score: 0 }, place: "Политехник. Поле 1", time: "10:00", date: "16 июня", league: "Суперлига" },
    { teamA: { logo: team, name: "Алгоритм", score: 4 }, teamB: { logo: team, name: "Крысиная Нора", score: 0 }, place: "Политехник. Поле 1", time: "10:00", date: "16 июня", league: "Суперлига" },
    { teamA: { logo: team, name: "Алгоритм", score: 4 }, teamB: { logo: team, name: "Крысиная Нора", score: 0 }, place: "Политехник. Поле 1", time: "10:00", date: "16 июня", league: "Суперлига" },
    { teamA: { logo: team, name: "Алгоритм1111", score: 4 }, teamB: { logo: team, name: "Крысиная Нора", score: 0 }, place: "Политехник. Поле 1", time: "10:00", date: "16 июня", league: "Суперлига" },
];

const series = [
    {key: 'won', logo: streak},
    {key: 'non_lost', logo: streak2},
    {key: 'scored', logo: streak3},
    {key: 'unconceded', logo: streak4}
]

const isValidPassword = (password) => {
    const regex = /^(?=.*\d)(?=.*[a-z])[0-9a-zA-Z]{8,}$/;
    return regex.test(password);
};

export default SideProfile;

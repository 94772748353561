import React, { useContext, useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";

import { postByUrl } from "../../../../v3MethodsService";

import ImageWithFallback from "../../Common/ImageWithFallback";
import {Loader} from "../../../Atoms";

import noImage from "../img/noImage.svg";
import "./style.scss";

const BindedPlayer = ({ state, notPlayerIds, clearBindedPlayer, getUserInfo }) => {
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const acceptHandler = () => {
        setIsValid(true);
        setIsLoading(true)
        postByUrl("clientAuth/changeProfile", {bindedPlayerId: state._id}).then((resp) => {
            if (resp && resp.success) {
                clearBindedPlayer();
                getUserInfo();
            }
            setIsLoading(false)
            setIsValid(false);
        });
    };

    const rejectHandler = () => {
        setIsValid(true);
        postByUrl("clientAuth/changeProfile", {notPlayerIds: [...notPlayerIds].concat(state._id)}).then((resp) => {
            if (resp && resp.success) {
                clearBindedPlayer();
            }
            setIsValid(false);
        });
    };

    return !isLoading ? (
        <div className="binded-player">
            <div className="binded-player__title">
                Возможно, это - Вы?
                <span>Объедините профили и Вам станут доступны расширенные возможности личного кабинета!</span>
            </div>
            <div className="binded-player__profile side-profile__account">
                <div className="side-profile__avatar binded-player__profile-avatar">
                    <ImageWithFallback src={state.avatarUrl} alt="avatar" fallback={<img src={noImage}/>}/>
                </div>
                <div className="side-profile__account-info">
                    <div className="side-profile__account-text">
                        <div className="side-profile__role">Футболист</div>
                        <div className="side-profile__account-main">{`${state.surname || ""} ${state.name || ""} ${state.middlename || ""}`}</div>
                        <div className="side-profile__account-bday">
                            Дата рождения
                            <span>{state.birthday || "Не указана"}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="binded-player__buttons">
                <Button className="auth2__button" label="Это я, объеденить профили" onClick={acceptHandler} disabled={isValid}/>
                <Button className="auth2__button auth2__button_outlined" label="Это другой человек" onClick={rejectHandler} disabled={isValid}/>
            </div>
        </div>
    ) : <div className="binded-player"><Loader/></div>
};

export default BindedPlayer;

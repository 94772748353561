import qs from 'qs'

import { diff } from "deep-object-diff";
import { useEffect } from "react";

const randomizeList = async (arr) => {
    let len = arr.length
    let currentIndex
    let usedIndexes = []
    let returnArray = []
    while (len > 0) {
        currentIndex = someIndex(arr.length)
        if (!usedIndexes.includes(currentIndex)) {
            usedIndexes.push(currentIndex)
            returnArray.push(arr[currentIndex])
            len --
        }
    }

    return returnArray
}

const someIndex = (len) => {
    return Math.floor(Math.random() * len);
}

const injectQueryPath = (k, v) => {
    const current = qs.parse(window.location.search.replace('?', ''))
    if(v) {
        current[k] = v
    } else {
        delete current[k]
    }

    return Object.entries(current).length ? `?${qs.stringify(current)}` : ''
}




const useClickOutside = (val, ref, cb) => {
    const handleClick = (e) => {
        if (ref.current && !ref.current.contains(e.target) && !e.target.closest('.p-dialog-mask')) {
            cb();
        }
    };

    useEffect(() => {
        if (val) {
            document.addEventListener("click", handleClick, { capture: true });
        } else {
            document.removeEventListener("click", handleClick, { capture: true });
        }

        return () => {
            document.removeEventListener("click", handleClick, { capture: true });
        };
    });
};

const diffData = (data, state) => {
      
    const prepareData = (obj) => {
      if(obj) {
        return Object.keys(obj)?.reduce((acc, row) => {
          if (Array.isArray(obj[row]) && obj[row].some((s) => s._id)) {
            //если масиив, то приводим к нужному виду
            acc[row] = obj[row].map((it) => it._id);
          } else if (
            typeof obj[row] === 'string' &&
            obj[row].match(/(\d+)-(\d+)-(\d+)\w(\d+):(\d+):(\d+)/gim)
          ) {
            //если дата, то приводим к new Date
            acc[row] = new Date(obj[row]);
          } else {
            acc[row] = obj[row];
          }
    
        return acc;
        }, {})
      }
    }
  
    const newState = prepareData(state);
    const newData = prepareData(data);

  
    const obj = diff(newData, newState);
    const arr = obj && Object.keys(obj);
    const resp = {};
  
    if (arr?.length) {
      arr.forEach((item) => {
        if (obj[item] !== undefined && obj[item]?.length) {
          resp[item] = newState[item];
        }
      });
    }
  
    return Object.keys(resp).length ? resp : false;
  };


export { randomizeList, injectQueryPath, useClickOutside, diffData }

import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { Tag } from 'primereact/tag'
import { ContentCard, AvatarShield, Achievement } from '../'
import './style.scss'

import axios from 'axios'
import { ENDPOINT } from '../../../env'

import renderAchievementText from '../Achievement/renderAchievementText'

import moment from 'moment'

const DPR = window.devicePixelRatio

const mergePull = arr => {
    const overall = arr.reduce((acc, t) => {
        acc = acc.concat(t)
        return acc
    }, []).filter(a => a.player && a.player.avatarUrl && !a.player.avatarUrl.includes('join'))

    return overall
            .sort((a, b) => {
                const dateDiff = moment(b.date, 'YY-MM-DD').unix() - moment(a.date, 'YY-MM-DD').unix()
                return dateDiff === 0 ? b.value - a.value : dateDiff
            })
            .slice(0, 15)
}

const AchievementsCarousel = ({ scope, subjectId, toursArr, pathname }) => {
    const [data, setData] = useState(null)

    const history = useHistory()

    useEffect(() => {
        if(subjectId) {
            axios.get(`${ENDPOINT}client/achievements/?sampleId=${subjectId}`)
                .then(resp => {
                    setData(resp.data && resp.data.length ? resp.data.slice(0,10) : [])
                })
        }
    }, [subjectId])

    useEffect(() => {
        if(toursArr && toursArr.length) {
            const loadTourAchs = tid => {
                return new Promise((resolve, reject) => {
                    axios.get(`${ENDPOINT}client/achievements/?sampleId=${tid}`)
                        .then(resp => {
                            resolve(resp.data)
                        })
                })
            }

            const achs = toursArr.map(tid => loadTourAchs(tid))
            Promise.all(achs)
                .then(pull => {
                    setData(mergePull(pull))
                })
        }
    }, [toursArr])

    return  <div className='achievements-carousel'>
                <ContentCard
                    title='Недавние достижения'
                    theme={{
                        surface: 'var(--achievement-widget-gradient)',
                        shadowColor: 'var(--achievement-widget-shadow)',
                        titleSurface: 'var(--achievement-widget-title-bgd)',
                        titleColor: 'var(--achievement-widget-title-color)'
                    }}
                >
                    {data ? !data.length ? (
                        <div className='content-empty'>
                            <span className='pi pi-star'></span>
                            <div>нет данных</div>
                        </div>
                    ) : (
                        <Splide
                            options={{
                                pagination: true,
                                fixedWidth: '100%',
                                focus: 0,
                                type: 'loop',
                                arrows: false,
                                gap: '10px',
                                interval: 4500,
                                perMove: 1,
                                updateOnMove: false,
                                waitForTransition: true,
                                clones: 1
                            }}
                        >
                            {data.filter(a => a.grade && a.player).map((a, i) => (
                                <SplideSlide>
                                    <div className='achievement-card'>
                                        <div className='info'>
                                            <div className='name alterfont' onClick={() => history.push(`/player/${a.player._id}`)}>{a.player.name} {a.player.surname}</div>
                                            <div className='team'>{a.team}</div>

                                            <div className='description'>{renderAchievementText(a, 'tournament', toursArr ? ' '+a.tournament.name : null)}</div>
                                            <Tag value={moment(a.date, 'YY-MM-DD').format('D MMMM YYYY')} />
                                        </div>

                                        <AvatarShield
                                            height={300}
                                            width={260}
                                            source={a.player.avatarUrl}
                                            action={() => history.push(`${pathname}/player/${a.player._id}`)}
                                        />
                                        <Achievement
                                            size={64}
                                            sample={a.sampleType}
                                            value={a.value}
                                            grade={a.grade.stripe}
                                            scope={a.scope}
                                            action={() => history.push(`${pathname}/player/${a.player._id}`)}
                                        />
                                    </div>
                                </SplideSlide>
                            ))}
                        </Splide>
                    ) : null}
                </ContentCard>
            </div>
}

export default AchievementsCarousel

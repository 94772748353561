import React, { useState, useContext } from 'react'
import {useHistory, useLocation} from "react-router-dom";
import Hotkeys from "react-hot-keys";

import {GlobalContext} from "../../../../../ctx";
import {postByUrl} from "../../../../../v3MethodsService";

import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
        
import './style.scss'

const SendEmail = ({  }) => {
    const history = useHistory()
    const location = useLocation()
    const [isValid, setIsValid] = useState(false);
    const [email, setEmail] = useState('')
    const [error, setError] = useState(false)

    const [global, setGlobal] = useContext(GlobalContext)
    const toast = global && global.toast ? global.toast : {}

    const sendHandler = async () => {
        setIsValid(true);
        const resp = await postByUrl('clientAuth/recovery', {"email": email})
        if (resp && resp.success) {
            setError(false)
            toast.show({ severity: "success", life: 4000, summary: "Письмо для восстановления пароля отправлено на почту" });
            history.replace(location.pathname)
            return
        }
            setError(true)
            setIsValid(false);
            toast.show({ severity: "error", life: 4000, summary: "Аккаунт с данной почтой еще не создан" });


    }

    const onKeyDown = async (e) => {
        if ((e === 'Enter' || e.key === 'Enter')) {
            await sendHandler()
        }
    }

    return (
        <div className='auth2__container recover-send-email'>
            <div className='auth2__title'>
                Восстановление доступа
            </div>
            <Hotkeys keyName="Enter" onKeyDown={onKeyDown}>
                <div className='auth2__inputs'>
                    <InputText value={email || ''} id='email' onChange={(e) => setEmail(e.target.value)} placeholder='Введите почту' onKeyDown={onKeyDown} className={`auth2__input${error ? ' auth2__input_invalid' : ''}`}/>
                    <Button label='Восстановить доступ' onClick={sendHandler} className='auth2__button' disabled={!(email && isValidEmail(email)) || isValid} />
                </div>
            </Hotkeys>
        </div>
    )
}

const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
    return regex.test(email);
}

export default SendEmail;

import React from 'react'
import qs from 'qs'

import { MatchesCarousel } from '../../'
import MobileMatches from '../../MatchesCarousel/Mobile'

import './style.scss'

const { matchesCarousel } = JSON.parse(localStorage.getItem('_am_appconfig'))

const Matches = ({ data, theme, team, pathname }) => {
    return  <div className='club-matches'>
                <MobileMatches
                    deftheme={theme}
                    extendedMode={true}
                    pathname={pathname}
                    scope='team'
                    subject={team || qs.parse(window.location.search.slice(1)).team}
                    layout='desktop'
                />
            </div>
}

export default Matches

import React, {useState} from 'react'

import Email from './Email'
import PersonData from './PersonData'

import './style.scss'

const Success = ({ tokenData, step, setStep }) => {

    const [state, setState] = useState({
        email: null,
        personData: null,
    })

    const Specified = wrap[step]

    return (
        <div className='auth2__container reg-success'>
            <Specified state={state} setState={setState} setStep={setStep} tokenData={tokenData}/>
        </div>
    )
}

const wrap = {
    email: Email,
    personData: PersonData,
}

export default Success
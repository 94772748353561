import React from 'react'
import { useHistory } from 'react-router-dom'
import { Tag } from 'primereact/tag'
import { ContentCard, AvatarShield, Achievement } from '../../Atoms'
import renderAchievementText from '../../Atoms/Achievement/renderAchievementText'

import moment from 'moment'

const Card = ({ data }) => {
    const history = useHistory()

    return  data.type === 'achievement' ? (
        <ContentCard
            theme={{
                surface: 'var(--achievement-widget-gradient)',
                shadowColor: 'var(--achievement-widget-shadow)',
                titleSurface: 'var(--achievement-widget-title-bgd)',
                titleColor: 'var(--achievement-widget-title-color)'
            }}
        >
            <div className='achievement-card'>
                <div className='info'>
                    <div className='name alterfont'>{data.player.name}</div>

                    <div className='description'>{renderAchievementText(data)}</div>
                    <Tag value={moment(data.date, 'YY-MM-DD').format('D MMMM YYYY')} />
                </div>

                <AvatarShield
                    height={300}
                    width={260}
                    source={data.player.avatarUrl}
                />
                <Achievement {...data} size={64} />
            </div>
        </ContentCard>
    ) : (
        <div className='feed-card' onClick={() => history.push(`${window.location.pathname}?publication=${data._id}`)}>
            <div className='preview'>
                <img src={data.preview} />
            </div>
            <div className='title'>
                <div>{data.title}</div>
                <span>{moment(data.date, 'YYYY-MM-DD').format('DD MMMM YYYYг')}</span>
            </div>
            {!!data.category && <div className='category alterfont'>#{data.category.toLowerCase()}</div>}
        </div>
    )
}

export default Card

import React from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { Emblem } from '../Atoms'
import './style.scss'
import {CONF} from "../../configs";

const MatchesList = ({ type, layout, group, data }) => {
    const history = useHistory()

    return  <div className={`matches-list ${layout}`}>
                <div className='container'>
                    <div className='primary'>
                        {data ? data.sort((a,b) => type === 'schedule' ?
                            group === 'bydate' ? (a.date === "null" ? 1 : b.date === "null" ? -1 : a.date > b.date ? 1 : -1) :
                                parseInt(a.matchday?.split(' ')[0]) ? parseInt(a.matchday?.split(' ')[0]) > parseInt(b.matchday?.split(' ')[0]) ? 1 : -1 : (a.date === "null" ? 1 : b.date === "null" ? -1 : a.date > b.date ? -1 : 1) : -1).map((gr, i) => (
                            <div className='matches-list_group' key={i}>
                                <div className='matches-list_group_title'>
                                    <span>{group === 'bydate' ? (gr.date && gr.date !== 'null' ? moment(gr.date, 'YY-MM-DD').format('D MMMM YYYY, (ddd)') : 'Дата не назначена') : gr.matchday}</span>
                                </div>
                                <div className='matches-list_group_list'>
                                    {
                                        //для lfl36 сортировка матчей по времени
                                        gr.list ? gr.list.filter(m => m.matchday).sort((a,b) => CONF.configName !== 'lfl36' ? 0 : a.time > b.time ? 1 : -1).map((m, i) => (
                                        <div
                                            className='matches-list_group_list_item'
                                            key={i}
                                            onClick={() => history.push(`/match/${m._id}`)}
                                        >
                                            <div className='time'>{type === 'schedule' ? group === 'bydate' ? (m.time ? m.time+', ' : '')+m.matchday.name : m.date && m.time ? moment(m.date+' '+m.time, 'YY-MM-DD HH:mm').format('DD.MM.YY'+(m.time ? ' HH:mm' : '')) : 'Дата не назначена' : m.time || '--:--'}</div>
                                            <div className='match'>
                                                <div className='team'>
                                                    <Emblem size='xs' source={m.home.emblem || m.home.club.emblem || ''} />
                                                    <span>{m.home.name}</span>
                                                </div>
                                                <div className='score'>
                                                    <div className='alterfont'>{m.scores ? m.scores.full.home : ''}</div>
                                                    <span className='pi pi-times'></span>
                                                    <div className='alterfont'>{m.scores ? m.scores.full.away : ''}</div>
                                                </div>
                                                <div className='team'>
                                                    <span>{m.away.name}</span>
                                                    <Emblem size='xs' source={m.away.emblem || m.away.club.emblem || ''} />
                                                </div>
                                            </div>
                                            <div className='location'>{m.location ? m.location.name : 'стадион не указан'}</div>
                                        </div>
                                    )) : null}
                                </div>
                            </div>
                        )) : null}
                    </div>

                    <div className='secondary'>
                    </div>
                </div>
            </div>
}

export default MatchesList

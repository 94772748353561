import React from 'react'
import { useHistory } from 'react-router-dom'
import './style.scss'

import Lottie from 'react-lottie'
import * as animationData from './coin.json'

import { CONF } from '../../../configs'
import { injectQueryPath } from '../../../utils'

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    speed: 0.5
}

const InMatchWidget = ({ data, width, layout, appUserId, fromStacks, fantasy }) => {
    const history = useHistory()

    const openBets = () => {
        if(appUserId && fromStacks && fantasy && fantasy.active) {
            history.push(`${window.location.pathname}${injectQueryPath('fantasy', true)}`)
        }
    }

    return  data ? <div
                className={`fantasy_match-widget mobile`}
                style={{
                    width: width
                }}
            >
                <div className='fantasy_match-widget_brand'>
                    {data.brand ? data.brand.type === 'image' ? (
                        <img src={data.brand.source} />
                    ) : (
                        <div className='fantasy_match-widget_brand_label'>
                            <span>{data.brand.text}</span>
                        </div>
                    ) : null}
                    <span>Fantasy</span>
                </div>
                <div className='fantasy_match-widget_values'>
                    <div className='fantasy_match-widget_values_stripes'>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className='fantasy_match-widget_values_body' onClick={() => openBets()}>
                        <div className='fantasy_match-widget_values_item'>
                            <div className='alterfont'>{data.w1 || '-'}</div>
                            <span>П1</span>
                        </div>
                        <div className='fantasy_match-widget_values_item'>
                            <div className='alterfont'>{data.wx || '-'}</div>
                            <span>X</span>
                        </div>
                        <div className='fantasy_match-widget_values_item'>
                            <div className='alterfont'>{data.w2 || '-'}</div>
                            <span>П2</span>
                        </div>
                    </div>
                </div>

                {fantasy && fantasy.active ? (
                    <div className='fantasy-announcement'>
                        <div>
                            <Lottie
                                options={defaultOptions}
                                width={60}
                                height={60}
                            />
                        </div>
                        <div className='description'>
                            <span>Мы запустили конкурс прогнозов 🎉</span>
                            {!fromStacks ? (
                                <span>Установи приложение лиги из мобильного браузера и войди в профиль, чтобы делать прогнозы на матчи и выигрывать призы!</span>
                            ) : appUserId ? (
                                <span>Тапни на коэффициент, чтобы поучаствовать!</span>
                            ) : (
                                <span>Чтобы начать участвовать, войди в аккаунт.</span>
                            )}
                        </div>
                    </div>
                ) : null}
            </div> : null
}

export default InMatchWidget

import React from 'react'
import { useHistory } from 'react-router-dom'

import { Emblem } from '../Atoms'

import './style.scss'

const transformName = str => {
    if(!str.includes(' ') && !str.includes('-') && str.length > 12) {
        return str.slice(0, str.length-5)+' '+str.slice(str.length-5)
    } else {
        return str
    }
}

const ScoreNode = ({ data, theme, proxyData }) => {

    const history = useHistory()

    return  <div className='match-main'>
                <div className='match-main_team __home' onClick={() => history.push(`/club/${data.home.club._id}?team=${data.home._id}`)}>
                    <Emblem backdroped={true} size='sm' source={data.home.club.emblem} />
                    <div className='match-team_name alterfont'>{transformName(data.home.name)}</div>
                </div>

                <div className='match-main_score'>
                    {proxyData && proxyData.time ? (
                        <div className='state-indicator'>
                            <i></i>
                            <span>{proxyData.finished ? 'завершён' : !proxyData.time.active ? 'перерыв' : `идёт ${proxyData.time.displayMinute} минута`}</span>
                        </div>
                    ) : null}

                    <div
                        className='match-main_score_cell alterfont'
                        style={{
                            background: `var(--match-main-score)`
                        }}
                    >{data.scores && data.scores.full ? data.scores.full.home : proxyData && proxyData.time && proxyData.score ? proxyData.score.home : '-'}</div>
                    <div
                        className='match-main_score_cell alterfont'
                        style={{
                            background: `var(--match-main-score)`
                        }}
                    >{data.scores && data.scores.full ? data.scores.full.away : proxyData && proxyData.time && proxyData.score ? proxyData.score.away : '-'}</div>

                    {data.scores && data.scores.shootout && data.scores.shootout.home ? (
                        <div className='shootout'>{`по пенальти: ${data.scores.shootout.home}:${data.scores.shootout.away}`}</div>
                    ) : null}
                </div>

                <div className='match-main_team __away' onClick={() => history.push(`/club/${data.away.club._id}?team=${data.away._id}`)}>
                    <Emblem backdroped={true} size='sm' source={data.away.club.emblem} />
                    <div className='match-team_name alterfont'>{transformName(data.away.name)}</div>
                </div>
            </div>

}

export default ScoreNode

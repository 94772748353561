import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Hotkeys from "react-hot-keys";
import { Tooltip } from "primereact/tooltip";
import { GlobalContext } from "../../../../../ctx";
import { postByUrl } from "../../../../../v3MethodsService";

import { Password } from "primereact/password";
import { Button } from "primereact/button";

import "./style.scss";

const ChangePass = ({ token }) => {
    const history = useHistory();

    const [state, setState] = useState({});
    const [error, setError] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const [global, setGlobal] = useContext(GlobalContext);
    const toast = global && global.toast ? global.toast : {};

    const inputHandler = (val, key) => {
        setIsValid(false);
        setState((prev) => ({ ...prev, [key]: val }));
    };

    const sendHandler = async () => {
        setIsValid(true);
        if (state && state.password === state.passwordRepeat) {
            if (isValidPassword(state.password)) {
                postByUrl("clientAuth/recovery_access", { recovery_token: token, password: state.password }).then((resp) => {
                    if (resp && resp.success) {
                        setError(false);
                        toast.show({ severity: "success", life: 4000, summary: "Пароль успешно изменен" });
                        history.push("/");
                        return
                    }
                    toast.show({ severity: "error", life: 4000, summary: "Ошибка изменения пароля" });
                });
                return;
            }
            toast.show({ severity: "error", life: 4000, summary: "Пароль должен быть: мин. 8 симв., латиница, мин. 1 буква, мин. 1 цифра" });
            setError(true);

            return;
        }
        setError(true);
        toast.show({ severity: "error", life: 4000, summary: "Пароли не совпадают" });
        setIsValid(false);
    };

    const onKeyDown = async (e) => {
        if (e === "Enter" || e.key === "Enter") {
            await sendHandler();
        }
    };

    return (
        <div className="auth2__container recover-change-pass">
            <Tooltip target=".custom-target-icon" position="top" />

            <div className="auth2__title tooltip-item">
                Восстановление доступа
                <i
                    className="custom-target-icon pi pi-info-circle p-text-secondary"
                    data-pr-tooltip="Требования&nbsp;к&nbsp;паролю: Мин&nbsp;8&nbsp;симв,&nbsp;латиница,&nbsp;мин&nbsp;1&nbsp;буква,&nbsp;мин&nbsp;1&nbsp;цифра"
                    style={{ cursor: "pointer" }}
                ></i>
            </div>
            <Hotkeys keyName="Enter" onKeyDown={onKeyDown}>
                <div className="auth2__inputs">
                    <Password
                        value={state.password || ""}
                        id="password"
                        onChange={(e) => inputHandler(e.target.value, "password")}
                        placeholder="Придумайте пароль"
                        onKeyDown={onKeyDown}
                        className={`auth2__password${error ? " auth2__password_invalid" : ""}`}
                        toggleMask
                        feedback={false}
                    />
                    <Password
                        value={state.passwordRepeat || ""}
                        id="passwordRepeat"
                        onChange={(e) => inputHandler(e.target.value, "passwordRepeat")}
                        placeholder="Повторите пароль"
                        onKeyDown={onKeyDown}
                        className={`auth2__password${error ? " auth2__password_invalid" : ""}`}
                        toggleMask
                        feedback={false}
                    />
                    <Button label="Сохранить изменения" onClick={sendHandler} className="auth2__button" disabled={!(state.password && state.passwordRepeat) || isValid} />
                </div>
            </Hotkeys>
        </div>
    );
};

const isValidPassword = (password) => {
    const regex = /^(?=.*\d)(?=.*[a-z])[0-9a-zA-Z]{8,}$/;
    return regex.test(password);
};

export default ChangePass;

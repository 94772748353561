import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Emblem } from '../Atoms'
import CustomScrollbars from 'react-custom-scrollbars-2'
import './style.scss'

import { ENDPOINT } from '../../env'

const formatText = (count, type) => {
    if (count % 10 === 1 && (Math.round(count / 10) !== 1)) {
        return ``
    } else if ([2, 3, 4].includes(count % 10) && (Math.round(count / 10) !== 1)){
        return `а`
    } else {
        return type === 'pld' ? `ей` : 'ов'
    }
}

const labels = {
    gs: 'гол',
    a: 'ассист',
    gas: 'гол+пас',
    cards: 'ЖК + КК'
}

const LeaderCard = ({ data, onResize, valuesModel }) => {
    const leaderRef = useRef(null)
    const history = useHistory()

    const onImgLoaded = () => {
        if(leaderRef && leaderRef.current) {
            onResize(leaderRef.current.clientHeight)
        }
    }

    return  data && data.team ? <div className='leader' ref={leaderRef}>
                <div className='leader-name'>
                    <span className='alterfont'>1.</span>
                    <div className='alterfont'>
                        <div>{data.name}</div>
                        <div>{data.team.name || data.team}</div>
                    </div>
                </div>
                <div className='leader-avatar' onClick={() => history.push(`/player/${data._id}`) }>
                    <img src={`${ENDPOINT}img/player/${data._id}?noholder=true&maxQty=true`} onLoad={onImgLoaded} onError={(e) => e.target.src = require('./football.png')} />
                </div>
                {data && data.data ? (
                    <div className='leader-values'>
                        <div><i className='alterfont'>{data.data.pld}</i>матч{formatText(data.data.pld, 'pld')}</div>
                        {valuesModel.length > 2 ? (
                            <div><i className='alterfont'>{data.data.yc}</i>ЖК + <i className='alterfont'>{data.data.rc}</i>КК</div>
                        ) : (
                            <div><i className='alterfont'>{data.data[valuesModel[1]]}</i>{labels[valuesModel[1]]}{formatText(data.data[valuesModel[1]], valuesModel[1])}</div>
                        )}
                    </div>
                ) : null}
            </div> : null
}

const Rankings = ({ layout, width, data, head, wrapped, theme, valuesModel, isExtraView, stagesStash, stageIdx }) => {
    const [leaderHeight, setLeaderHeight] = useState(550)
    const fullMode = layout !== 'mobile' && !isExtraView

    const history = useHistory()

    const proxy = (data && isExtraView) ? data.map(p => ({
        ...p,
        team: p.team.name,
        values: valuesModel.map(k => p[k])
    })) : data && data[0] && data[0].data ? data.filter(p => p && p.data).map(p => ({
        ...p,
        values: valuesModel.map(k => p.data[k])
    })) : null

    const table = proxy ? stagesStash && stagesStash.filter(s => s.type !== 'playoff').length > 1 && stagesStash[stageIdx] ? proxy.filter(p => stagesStash[stageIdx].teams.includes(p.teamId)) : [...proxy] : []

    const body = [
        <div className='rankings-row rankings-head' key={'head'}>
            <div className='rankings-player'></div>
            <div className='rankings-values'>
                {head.map((h, i) => (
                    <div className='rankings-values_item' key={i}>{h}</div>
                ))}
            </div>
        </div>,
        <div className={wrapped ? 'content-body' : ''} key='body'>
            {table && table.length ? table.slice(fullMode ? 1 : 0).map((p, i) => (
                <div
                    className='rankings-row'
                    style={{
                        background: i%2!==0 ? theme.even.includes('#') ? theme.even : `var(--${theme.even})` : '',
                        borderTop: i%2!==0 ? `1px solid ${ theme.evenBorder.includes('#') ? theme.evenBorder : `var(--${theme.evenBorder})`}` : 'none'
                    }}
                    key={i}
                >
                    <div className='rankings-player' onClick={() => history.push(`/player/${p._id}`)}>
                        <div className='rankings-player_pos'>{i+(fullMode ? 2 : 1)}</div>
                        <Emblem size='xxs' player={true} backdroped={false} source={`${ENDPOINT}img/player/${p._id}?noholder=true`} />
                        <div className='rankings-player_info'>
                            <div>{p.name}</div>
                            <div style={{color: `var(--${theme.text}-400)`}}>{p.team}</div>
                        </div>
                    </div>
                    <div className='rankings-values'>
                        {p.values ? p.values.map((v, i) => (
                            <div className='rankings-values_item' key={i}>{v}</div>
                        )) : null}
                    </div>
                </div>
            )) : 'Нет данных'}
        </div>
    ]

    return  <div
                className={`rankings ${layout}`}
                style={{
                    width: width,
                    color: `var(--${theme.text}-700)`
                }}
            >
                {fullMode ? (
                    <div className='container full-mode'>
                        {table && table.length ? <LeaderCard
                            data={table[0]}
                            onResize={h => setLeaderHeight(h)}
                            valuesModel={valuesModel}
                        /> : null}

                        <div className='rest'>
                            <CustomScrollbars autoHeight autoHeightMin={leaderHeight}>
                                {body}
                            </CustomScrollbars>
                        </div>
                    </div>
                ) : body}
            </div>
}

export default Rankings

const points = {
    origin: 'https://proxy.amateum.com/',
    //test: 'https://api.rfsgr.ru/',
    test: 'https://api-mf.rfs.ru/',
    preprod: 'https://preprod-api-mf.rfs.ru/',
    sfl_preprod: 'https://preprod-api-sfl.rfs.ru/',
    prod: 'https://api-mf.rfs.ru/',
    sfl_prod: 'https://api-sfl.rfs.ru/'
}

const fantasyPoints = {
    origin: 'https://fantasy.amateum.com/',
    preprod: 'https://preprod-fantasy-api.rfs.ru/',
    prod: 'https://fantasy-api.rfs.ru/'
}

const betsFramePoints = {
    origin: 'https://bets.amateum.com/',
    preprod: 'https://preprod-vmf-fantasy.rfs.ru/',
    prod: 'https://vmf-fantasy.rfs.ru/'
}

const domains = {
    test: 'https://dev-admin.amateum.com/',
    preprod: 'https://preprod-admin-vmf.rfs.ru/',
    prod: 'https://admin-vmf.rfs.ru/'
}

export const ADMINENDPOINT = domains[process.env.INSTANCE] || 'https://admin-vmf.rfs.ru/'

export const ENDPOINT = points[process.env.INSTANCE] || 'https://api-mf.rfs.ru/'
export const NEWENDPOINT = points[process.env.INSTANCE] || 'https://api-mf.rfs.ru/'
export const BETS_BASE = betsFramePoints[process.env.INSTANCE] || 'https://bets.amateum.com/'
export const FANTASY = fantasyPoints[process.env.INSTANCE] || 'https://fantasy.amateum.com/'

export const SITE_KEY = "6LfTvR8qAAAAAMk4XMgYb2Ey-z4kMkmwOiGrn8BT"
import axios from "axios";
import { NEWENDPOINT } from '../env'

let headers = {
    "Content-Type": "application/json",
    "Authorization": localStorage.getItem('_amateum_tkn'),
    "scopetoken": localStorage.getItem('_amateum_tkn')
}

const instance = axios.create({
    baseURL: `${NEWENDPOINT}/`,
    headers: headers
})

const getByUrl = async (url) => {
    try {
        if (instance.defaults.headers["Authorization"] !== localStorage.getItem('_amateum_tkn')) {
            instance.defaults.headers["Authorization"] = localStorage.getItem('_amateum_tkn')
        }
        let req = await instance.get(url)
        if (req.data) {
            if (req.data.msg) console.log(req.data.msg);
            return req.data
        }
    } catch (e) {
        console.log(e)
        return e.response && e.response.data ? e.response.data : false
    }
}

const postByUrl = async (url, data) => {
    try {
        let req = await instance.post(url, data)
        if (req.data) {
            if (req.data.msg) console.log(req.data.msg);
            return req.data
        }
    } catch (e) {
        console.log(e)
        return e.response && e.response.data ? e.response.data : false
    }
}

const putByUrl = async (url, data) => {
    try {
        let req = await instance.put(url, data)
        if (req.data) {
            if (req.data.msg) console.log(req.data.msg);
            return req.data
        }
    } catch (e) {
        console.log(e)
        return e.response && e.response.data ? e.response.data : false
    }
}

const deleteByUrl = async (url) => {
    try {
        let req = await instance.delete(url)
        if (req.data) {
            if (req.data.msg) console.log(req.data.msg);
            return req.data
        }
    } catch (e) {
        console.log(e)
        return e.response && e.response.data ? e.response.data : false
    }
}

export { getByUrl, postByUrl, putByUrl, deleteByUrl }
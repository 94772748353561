import React, {useState, useEffect, useContext} from 'react'
import {useHistory, useLocation} from "react-router-dom";
import Hotkeys from "react-hot-keys";
import ReCAPTCHA from "react-google-recaptcha";
import {SITE_KEY} from "../../../../env";

import {GlobalContext} from "../../../../ctx";

import {postByUrl} from "../../../../v3MethodsService";

import { Divider } from 'primereact/divider';
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
        
import './style.scss'

const Login = ({ updateGlobal }) => {
    const history = useHistory()
    const location = useLocation()

    const [state, setState] = useState(null)
    const [isValid, setIsValid] = useState(false);
    const [error, setError] = useState(false)
    const [useCaptcha, setUseCaptcha] = useState(false);

    const [global, setGlobal] = useContext(GlobalContext)
    const toast = global && global.toast ? global.toast : {}

    useEffect(() => {
        if (state) {
            const notValid = formData.map(f => !state[f.key]).filter(f => f)
            setIsValid(!notValid.length)
        } else {
            setIsValid(false)
        }
    }, [state]);

    const inputHandler = (val, key) => {
        setState(prev => ({...prev, [key]: val}))
    }

    const sendHandler = async () => {
        setIsValid(false);
        postByUrl('clientAuth/signin', state)
            .then(resp => {
                if (resp && resp.success) {
                    localStorage.removeItem("_login_attempt_count");
                    updateGlobal(resp.token, 'token')
                    localStorage.setItem('_amateum_tkn', resp.token)
                    /*setTimeout(() => {
                        window.location.reload()
                    }, 300)*/
                } else {
                    toast.show({ severity: "error", life: 4000, summary: resp.message || 'Ошибка сервера. Повторите попытку позже.' });
                    setIsValid(true);
                    const loginAttemptCount = parseInt(localStorage.getItem("_login_attempt_count"));
                    localStorage.setItem("_login_attempt_count", (loginAttemptCount || 0) + 1);
                    if (loginAttemptCount >= 9) {
                        setUseCaptcha(true);
                        postByUrl('methodsV3/logs', {
                            "action": "post",
                            "event": "PUBLIC_USER_AUTH_MAX_FAILED",
                            "affected_type": "appusers",
                            "affected_id": "",
                            "severity": "WARNING",
                            "authorCollection": "appusers",
                            "controlled_by": "/clientAuth/signin",
                            "user": "",
                            "author": state.email
                        })
                    }
                }
            })
    }

    const gosHandler = () => {
        console.log('gos')
    }

    const onKeyDown = async (e) => {
        if ((e === 'Enter' || e.key === 'Enter')) {
            await sendHandler()
        }
    }

    return (
        <div className='auth2__container login'>
            <div className='auth2__title'>
                Вход в личный кабинет
            </div>
            <Hotkeys keyName="Enter" onKeyDown={onKeyDown}>
                <div className='auth2__inputs'>
                    {formData.map(f => renderField(state, f, inputHandler, error))}
                    <Button label='Войти' onClick={sendHandler} className='auth2__button' disabled={!isValid || useCaptcha}/>
                    <label className='auth2__checkbox-label'><a onClick={() => history.push('?recover')} target='_self'>Не помню пароль</a></label>
                </div>
                {useCaptcha && <ReCAPTCHA sitekey={SITE_KEY} onChange={() => setUseCaptcha(false)}/>}
                <div className='login__line'>
                    <Divider/>
                    или
                    <Divider/>
                </div>
                <div className='auth2__link' onClick={() => history.push('?reg')}>Создать учетную запись</div>
                {/*<Button label='Войти через Госуслуги' onClick={gosHandler} className='auth2__button auth2__button_outlined'/>*/}
            </Hotkeys>
        </div>
    )
}

const formData = [
    {key: "email", placeholder: "Введите почту", type: "input", size: "full"},
    {key: "password", placeholder: "Введите пароль", type: "password", size: "full"},
]

const renderField = (state, field, inputHandler, error) => {
    switch (field.type) {
        case 'input': return <InputText id={field.key} key={field.key} value={state?.[field.key] || ''} onChange={(e) => inputHandler(e.target.value, field.key)} placeholder={field.placeholder} className={`auth2__input${error ? ' auth2__input_invalid' : ''}`}/>
        case 'password': return <Password id={field.key} key={field.key} value={state?.[field.key] || ''} onChange={(e) => inputHandler(e.target.value, field.key)} placeholder={field.placeholder} className={`auth2__password${error ? ' auth2__password_invalid' : ''}`} toggleMask feedback={false}/>
        default: return null
    }
}


export default Login;

import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { ContentTabs, IconsNavi } from '../Atoms'

import './style.scss'

import { tabs, navi } from './config'
import Content from './Content'

const SubContent = ({ active, path, setNav, nav, tab, playoff }) => {
    return  <div
                className={`subcontent${active ? ' active' : ''}`}
            >
                {navi[tab] ? <IconsNavi
                    layout='desktop'
                    iconsStyle={'lineal'}
                    active={nav}
                    theme={{
                        activeSurface: 'blue-50',
                        activeAccent: 'blue-600',
                        activeShadow: 'rgba(54, 120, 230, 0.2)',
                        surface: 'orange-50',
                        accent: 'bluegray-600',
                        shadow: '#f1e7e0'
                    }}
                    items={playoff && tab === 0 ? [{path: 'standings', icon: 'trophy', label: 'сетка'}].concat(navi[0].slice(2)) : navi[tab]}
                    onChange={i => setNav(i)}
                /> : null}
            </div>
}

const Tournament = ({ layout, width, theme, pathname }) => {
    const [tab, setTab] = useState(0)
    const [nav, setNav] = useState(0)
    const [playoff, setPlayoff] = useState(false)
    const [overrideNavi, setOverrideNavi] = useState(null)

    const { id } = useParams()

    useEffect(() => {
        setNav(0)
        setTab(0)
    }, [id])

    useEffect(() => {
        setNav(0)
    }, [tab, playoff])

    return  layout === 'mobile' ? <div
                className={`tournament ${layout}`}
                style={{
                    width: width
                }}
            >
                {layout !== 'mobile' ? <ContentTabs
                    model={tabs}
                    width={width}
                    theme='blue'
                    activeIdx={tab}
                    onChange={e => setTab(e.index)}
                /> : null}

                {navi[tab] ? <IconsNavi
                    layout='mobile'
                    iconsStyle={'lineal'}
                    active={nav}
                    theme={{
                        activeSurface: 'blue-50',
                        activeAccent: 'blue-600',
                        activeShadow: 'rgba(54, 120, 230, 0.1)',
                        surface: 'orange-50',
                        accent: 'bluegray-600',
                        shadow: '#FFF8F3'
                    }}
                    items={overrideNavi ? overrideNavi[tab] : navi[tab]}
                    onChange={i => setNav(i)}
                /> : null}

                <Content
                    tabs={tabs}
                    _navi={navi}
                    activeTab={tab}
                    activeNav={nav}
                    width={width}
                    layout={layout}
                    setPlayoff={setPlayoff}
                    isPlayoff={playoff}
                    setOverrideNavi={setOverrideNavi}
                />
            </div> : (
                <div
                    className={`tournament ${layout}`}
                    style={{
                        width: width,
                        backgroundColor: theme.surface.includes('#') ? theme.surface : `var(--${theme.surface})`
                    }}
                >
                    <div className="container">
                        <div className='content-btns'>
                            {tabs.map((t, i) => [
                                <div
                                    className='content-btn alterfont'
                                    key={i}
                                    onClick={() => setTab(i)}
                                    // style={{
                                    //     background: tab === i ? `var(--blue-500)` : '#fff',
                                    //     color: tab === i ? `var(--blue-50)` : `var(--blue-500)`,
                                    //     boxShadow: `2px 2px ${tab === i ? '25px' : '10px'} var(--blue-${tab === i ? '50' : '100'})`
                                    // }}
                                >
                                    <div className={`pi pi-${t.icon}`}></div>
                                    <div>{t.label}</div>
                                </div>,
                                <SubContent
                                    active={tab===i}
                                    path={t.path}
                                    setNav={setNav}
                                    nav={nav}
                                    tab={tab}
                                    playoff={playoff}
                                />
                            ])}
                        </div>

                        <Content
                            tabs={tabs}
                            _navi={navi}
                            activeTab={tab}
                            activeNav={nav}
                            width={width}
                            layout={layout}
                            setPlayoff={setPlayoff}
                            isPlayoff={playoff}
                            pathname={pathname}
                        />
                    </div>
                </div>
            )
}

export default Tournament

import React, { useState, useEffect, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Skeleton } from 'primereact/skeleton'
import { TabMenu } from 'primereact/tabmenu'
import StoriesNav from './StoriesNav'
import ItemsNav from './ItemsNav'
import Item from './Item'
import { NonIdealState } from '../Atoms'

import axios from 'axios'
import { ENDPOINT } from '../../env'
import { injectQueryPath } from '../../utils'
import DesktopContext from '../../Layouts/Desktop/ctx'
import { CONF } from '../../configs'

import moment from 'moment'

import './style.scss'
import './team-scoped.scss'

const replaceMixed = arr => {
    return arr.map(cat => ({
        ...cat,
        data: cat.data.map(pub => ({
            ...pub,
            type: pub.attachments && pub.attachments.length === 1 && pub.attachments[0].type === 'video' ? 'video' : pub.type
        }))
    }))
}

const MediaBlock = ({ layout, width, theme, scope, subjectId, inPage }) => {
    const [category, setCategory] = useState(null)
    const [itemIdx, setItemIdx] = useState(0)
    const [showcase, setShowcase] = useState(null)

    const { id } = useParams()
    const history = useHistory()

    const ctx = useContext(DesktopContext)

    useEffect(() => {
        if(showcase && showcase.length) {
            const cat = showcase.find(c => c.data.length)
            setCategory(cat)
        }
    }, [showcase])

    useEffect(() => {
        if(subjectId || id) {
            setShowcase(null)
            axios.get(`${ENDPOINT}client/mediablock/${subjectId || id ||''}${(!subjectId && id) ? '?scope=tag' : scope ? '?scope='+scope : ''}`)
                .then(resp => {
                    if (CONF.configName === 'lfl36'){
                        const lfl36case = ['news', 'broadcast', 'highlights', 'photos', 'interview', 'previews']
                        const newResp = resp.data.map((item, index) => {
                            return resp.data.filter(rd => rd.id === lfl36case[index])
                        })
                        setShowcase(replaceMixed(newResp.flat(1)))
                    } else setShowcase(replaceMixed(resp.data))
                })
        }
    }, [subjectId, id])

    const cleanupTags = arr => {
        const matchesString = arr.filter(t => t.type === 'matches').map(t => t.text).join(',')
        return arr.filter(t => !(t.type === 'teams' && matchesString.includes(t.text)))
    }

    return  <div
                className={`mediablock ${layout}${scope ? ' '+scope+'-scoped' : ''}${inPage ? ' in-page' : ''}`}
                style={{
                    width: width,
                    height: layout === 'mobile' ? ((width*1.78)-88) : 'unset',
                    backgroundColor: inPage ? 'transparent' : `var(--top-mediablock-bgd)`,
                    boxSizing: 'border-box'
                }}
            >
                {layout !== 'mobile' ? (
                    <div className='stripes'>
                        <div style={{backgroundColor: `var(--${theme.surfaceArea}-600)`}}></div>
                        <div style={{backgroundColor: `var(--${theme.surfaceArea}-800)`}}></div>
                    </div>
                ) : null}

                {layout !== 'mobile' ? showcase && showcase.length ? (
                    <div className='container' style={{width: inPage ? width : ''}}>
                        <div className='item-wrap'>
                            {!showcase || !category || !category.data[itemIdx] ? (
                                <Skeleton className='item-skeleton' width='100%' height='78%' />
                            ) : (
                                <Item
                                    key='mb-item'
                                    layout='desktop'
                                    data={category.data[itemIdx]}
                                    theme={theme}
                                />
                            )}
                        </div>

                        <div className='content-wrap'>
                            <div className='categories'>
                                {!showcase || !category ? (
                                    <div className='category-row'>
                                        <Skeleton width="30%" height="30px" />
                                        <Skeleton width="30%" height="30px" />
                                        <Skeleton width="30%" height="30px" />
                                    </div>
                                ) : (
                                    <div className='category-row loaded'>
                                        {showcase.map(cat => (
                                            <div
                                                className='category alterfont'
                                                key={cat.id}
                                                style={{
                                                    backgroundColor: cat.id === category.id ? `var(--${theme.showcaseArea}-500)` : 'transparent',
                                                    borderColor: cat.id === category.id ? 'transparent' : `var(--${theme.showcaseArea}-${scope ? '500' : '50'})`,
                                                    color: cat.id === category.id && scope ? `var(--${theme.showcaseArea}-50)` : `var(--${theme.showcaseArea}-${scope ? '500' : '50'})`
                                                }}
                                            >{cat.label}</div>
                                        ))}
                                    </div>
                                )}
                            </div>

                            <TabMenu model={showcase || []} onTabChange={e => {setCategory(showcase[e.index]); setItemIdx(0)}} activeIndex={showcase && category ? showcase.findIndex(s => s.id === category.id) : 0} />

                            <div className='content'>
                                <div className='item-intro'>
                                    {!showcase || !category ? [
                                        <Skeleton width="150px" height="16px" key={0} />,
                                        <Skeleton width="100%" height="68px" key={1} />
                                    ] : category.data[itemIdx] ? [
                                        <div
                                            key='date'
                                            className='item-content_date'
                                            style={{color: `var(--${theme.metaColor})`}}
                                        >{moment(category.data[itemIdx].date+' '+category.data[itemIdx].time, 'YY-MM-DD HH:mm').format('dddd, DD MMMM, HH:mm').replace(', 00:00', '')}</div>,
                                        <div
                                            key='title'
                                            className={'item-content_title alterfont'+((ctx && category.data[itemIdx].type === 'mixed') ? ' clickable' : '')}
                                            style={{color: `var(--${theme.titleColor})`}}
                                            onClick={() => {
                                                if(ctx && category.data[itemIdx].type === 'mixed') {
                                                    history.push(`${window.location.pathname}${injectQueryPath('publication', category.data[itemIdx]._id)}`)
                                                    ctx.setPublicationId(category.data[itemIdx]._id)
                                                }
                                            }}
                                        >{category.data[itemIdx].title}</div>,
                                        <div className='tags'>{cleanupTags(category.data[itemIdx].tags.filter(tag => tag.subjectId !== id && (!tag.teams || tag.teams.clubId !== id))).map((tag, i) => (
                                            <div className='tag-item' onClick={() => history.push(`/${tag.type === 'teams' ? 'club' : tag.type === 'matches' ? 'match' : tag.type.slice(0, -1)}/${tag.type === 'teams' ? tag.teams.clubId : tag.subjectId}`)}>#{tag.text}</div>
                                        ))}</div>
                                    ] : null}
                                </div>

                                <div className="previews">
                                    <ItemsNav
                                        activeIdx={itemIdx}
                                        setActiveIdx={setItemIdx}
                                        category={category}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : inPage ? (
                    <div className='empty'>
                        <NonIdealState
                            icon='strategy'
                            text='ещё нет публикаций об этой команде'
                        />
                    </div>
                ) : null : category ? [
                    <StoriesNav
                        key='mb'
                        data={showcase}
                        theme={theme}
                        active={category.id}
                        setActive={id => {
                            const cat = showcase.find(c => c.id === id)
                            setCategory(cat)
                            setItemIdx(0)
                        }}
                    />,
                    category.data[itemIdx] ? <Item
                        key='mb-item'
                        layout='mobile'
                        data={category.data[itemIdx]}
                        theme={theme}
                    /> : null
                ] : null}
            </div>
}

export default MediaBlock

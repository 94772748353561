import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { MatchesBalance } from '../Atoms'
import { InMatchWidget } from '../Fantasy'

import { FANTASY } from '../../env'
import { CONF } from '../../configs'
import axios from 'axios'

const roles = {
    referee: 'Арбитр',
    executive: 'Делегат',
    medicine: 'Медик'
}

const findRoleIndex = entry => {
    const keys = Object.keys(roles)

    return keys.findIndex(k => k === entry.role)
}

const Info = ({ data, layout, matchId, appUser, fromStacks, pathname, fantasy }) => {
    const [history, setHistory] = useState(null)
    const [chances, setChances] = useState(null)

    const hst = useHistory()

    useEffect(() => {
        if(matchId) {
            axios.get(`${FANTASY}book/getLineBets/${matchId}`)
                .then(resp => {
                    if(resp.data && resp.data.history) {
                        const _h = resp.data.history
                        setHistory({
                            totalScore: _h.gs+':'+_h.gc,
                            home: _h.w,
                            draws: _h.d,
                            away: _h.l
                        })

                        setChances(resp.data.chances || {})
                    }
                })
        }
    }, [matchId])

    const staff = data && data.matchday && data.matchday.employees && data.matchday.employees.length ? data.matchday.employees.sort((a, b) => findRoleIndex(a) - findRoleIndex(b)) : null

    return  layout === 'mobile' ? <div>
                <div className='match-general_info'>
                    <div>{data.matchday.division+', '+data.matchday.matchday}</div>
                    <div>{data.matchday.time+', '+data.matchday.time+', '+data.matchday.pitch}</div>
                    <div>Арбитры: не указан</div>
                </div>

                <MatchesBalance
                    data={history}
                    theme={{
                        bar: 'green'
                    }}
                />

                {fantasy && fantasy.active ? <InMatchWidget
                    layout='desktop'
                    appUserId={appUser ? appUser._id : null}
                    fantasy={fantasy}
                    data={chances ? {
                        ...chances,
                        brand: fantasy.brand || {text: 'РФС'},
                    } : null}
                /> : null}
            </div> : (
                <div className='match-info_grid'>
                    <div className='match-general_info'>
                        <div>
                            <div className='mean interactive'>
                                <span
                                    onClick={() => hst.push(`${pathname}/tournament/${data.matchday.tournamentId}`)}
                                >{data.matchday.division}</span>
                            </div>
                            <div className='meta'>{data.matchday.matchday}</div>
                            <div className='mean'>{data.matchday.date}</div>
                            <div className='meta'>{data.matchday.pitch !== '---' ? data.matchday.pitch : ''} {data.matchday.time || '--:--'}</div>
                        </div>

                        {staff ? (
                            <div className='match-staff'>
                                {staff.filter(e => roles[e.role]).map((s, i) => (
                                    <div className='match-staff_item' key={i}>
                                        <div>{roles[s.role]}</div>
                                        <div>{s.name}</div>
                                    </div>
                                ))}
                            </div>
                        ) : null}
                    </div>

                    <div>
                        <div>
                            {fantasy && fantasy.active ? (
                                <InMatchWidget
                                    layout='desktop'
                                    fantasy={fantasy}
                                    fromStacks={fromStacks}
                                    appUserId={appUser ? appUser._id : null}
                                    data={chances ? {
                                        ...chances,
                                        brand: fantasy.brand || {text: 'РФС'},
                                    } : null}
                                />
                            ) : null}
                        </div>
                        <MatchesBalance
                            data={history}
                            theme={{
                                bar: 'green'
                            }}
                        />
                    </div>
                </div>
            )
}

export default Info

import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { motion, AnimatePresence } from 'framer-motion'

import Card from './Card'
import moment from 'moment'

import { injectQueryPath } from '../../../utils'

const renderDateTime = ({ date, time }) => {
    let ds
    let ts = ''

    switch (true) {
        case date === moment().format('YY-MM-DD'):
            ds = 'сегодня'
            ts = time
            break
        case date === moment().add(-1, 'days').format('YY-MM-DD'):
            ds = 'вчера'
            break
        case date === moment().add(1, 'days').format('YY-MM-DD'):
            ds = 'завтра'
            ts = time
            break
        default:
            ds = moment(date, 'YY-MM-DD').format('YY') === moment().format('YY') ? moment(date, 'YY-MM-DD').format('D MMM') : moment(date, 'YY-MM-DD').format('DD.MM.YY')
            break
    }

    return [
        <span className='date'>{ds}</span>,
        <span className='time'>{ts}</span>
    ]
}



const Section = ({ title, type, data, layout, pathname }) => {
    const [listMode, setListMode] = useState(window.location.search && window.location.search.includes('view=list'))
    const [offset, setOffset] = useState(0)
    const history = useHistory()

    useEffect(() => {
        const query = injectQueryPath('view', listMode ? 'list' : null)
        window.history.pushState({}, null, `${window.location.pathname}${query}`)
    }, [listMode])

    return (!data || !data.length) ? (
            <div
                className={'mobile-matches_section empty'}
            >
                <div className='title alterfont'>{title}</div>

                <div className='holder'>
                    <span className='pi pi-calendar'></span>
                    <div>нет информации</div>
                </div>
            </div>
        ) : (
            <div
                className={'mobile-matches_section'+(listMode ? ' list-mode' : '')}
            >
                <div className='title alterfont'>{title}</div>

                <div className='mode-switch' onClick={() => setListMode(!listMode)} style={listMode ? {right: 80} : {}}>
                    <span className={`pi pi-clone ${!listMode ? 'selected' : ''}`}></span>
                    <span className={`pi pi-list ${listMode ? 'selected' : ''}`}></span>
                </div>

                {listMode ? (
                    <div className='mode-switch'>
                        <span className={`pi pi-caret-left ${offset > 0 ? 'enabled' : ''}`} onClick={() => setOffset(offset > 0 ? offset-1 : 0)}></span>
                        <span className={`pi pi-caret-right ${offset < (Math.ceil(data.length/10) - 1) ? 'enabled' : ''}`} onClick={() => setOffset(offset < (Math.ceil(data.length/10) - 1) ? offset+1 : offset)}></span>
                    </div>
                ) : null}

                <AnimatePresence
                    mode="popLayout"
                >
                    {!listMode ? (
                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                        >
                            <Splide
                                options={{
                                    pagination: true,
                                    fixedWidth: 168,
                                    focus: layout !== 'desktop' ? 'center' : 'start',
                                    type: (data.length > 3) ? 'loop' : null,
                                    arrows: false,
                                    gap: '10px',
                                    interval: 4500,
                                    perMove: 1,
                                    updateOnMove: false,
                                    waitForTransition: true,
                                    clones: 0
                                }}
                            >
                                {data.slice(0, 20).map((m, i) => (
                                    <SplideSlide key={i}>
                                        <div className='mobile-matches_item'>
                                            <Card data={m} pathname={pathname} />
                                        </div>
                                    </SplideSlide>
                                ))}
                            </Splide>
                        </motion.div>
                    ) : null}
                </AnimatePresence>

                <AnimatePresence
                    mode="popLayout"
                >
                    {listMode ? (
                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            className='mobile-matches_list'
                        >
                            {data.slice(offset*10, (offset+1)*10).map((m, i) => (
                                <div className='mobile-matches_list-item' onClick={() => history.push(`/match/${m._id}`)}>
                                    <div className='mean'>
                                        {renderDateTime(m)}
                                    </div>
                                    <div className='teams'>
                                        <span>{m.home.name}</span>
                                        <span className='pi pi-times'></span>
                                        <span>{m.away.name}</span>
                                    </div>

                                    {m.score ? (
                                        <div className='score alterfont'>{m.score.full.home}:{m.score.full.away}</div>
                                    ) : <div className='score'></div>}
                                </div>
                            ))}
                        </motion.div>
                    ) : null}
                </AnimatePresence>
            </div>
        )
}

export default Section

import React, {useState, useEffect, useContext} from 'react'
import {useHistory, useLocation} from "react-router-dom";
import Hotkeys from "react-hot-keys";

import {GlobalContext} from "../../../../../../ctx";
import {postByUrl} from "../../../../../../v3MethodsService";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import {Divider} from "primereact/divider";

import './style.scss'

const Email = ({ state, setState, setStep }) => {
    const history = useHistory()
    const location = useLocation()
    const [global, setGlobal] = useContext(GlobalContext)
    const federation = JSON.parse(localStorage.getItem('_am_federation'))

    const toast = global && global.toast ? global.toast : {}

    const changeHandler = (val, key) => {
        setState(prev => ({...prev, [key]: val}))
    }

    const sendHandler = async () => {
        postByUrl('clientAuth/create', {email: state.email, scope: federation})
            .then(resp => {
                if (resp && resp.success) {
                    toast.show({ severity: "success", life: 4000, summary: resp.message || 'Письмо для завершения регистрации отправлено на почту.' });
                    history.replace(location.pathname)
                    return
                }
                toast.show({ severity: "error", life: 4000, summary: resp.message || 'Ошибка сервера. Повторите попытку позже.' });
            })
    }

    const onKeyDown = async (e) => {
        if ((e === 'Enter' || e.key === 'Enter')) {
            await sendHandler()
        }
    }

    return (
        <div className='reg-success-email'>
            <div className='auth2__title'>
                Создание учетной записи
            </div>
            <Hotkeys keyName="Enter" onKeyDown={onKeyDown}>
                <div className='auth2__inputs'>
                    <InputText value={state.email || ''} id='email' onChange={(e) => changeHandler(e.target.value, 'email')} placeholder='Введите почту' onKeyDown={onKeyDown} className='auth2__input'/>
                    <Button label='Отправить приглашение на email' onClick={sendHandler} className='auth2__button' disabled={!(state.email && isValidEmail(state.email))}/>
                </div>
                <div className='login__line'>
                    <Divider/>
                    или
                    <Divider/>
                </div>
                <div className='auth2__link' onClick={() => history.replace(location.pathname)}>Уже есть учетная запись? Войдите</div>
            </Hotkeys>
        </div>
    )
}

const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
    return regex.test(email);
}

export default Email
import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { TabMenu } from 'primereact/tabmenu'
import { motion, AnimatePresence } from 'framer-motion'

import { Root, TournamentStack, PlayerStack, MatchStack, ClubStack, TagStack } from './Types'
import { Loader } from '../../../Components/Atoms'

import areasModel from './areasModel'

import LayoutContext from '../ctx'
import { injectQueryPath } from '../../../utils'
import qs from 'qs'

import './style.scss'

const pages = {
    root: Root,
    tournament: TournamentStack,
    player: PlayerStack,
    match: MatchStack,
    club: ClubStack,
    feed: TagStack
}

const Stack = ({ page }) => {
    const [activeArea, setActiveArea] = useState(0)
    const [stackScrollClass, setStackScrollClass] = useState(null)

    const Page = page ? pages[page] || null : null
    const { id } = useParams()

    const ctx = useContext(LayoutContext)

    useEffect(() => {
        const query = qs.parse(window.location.search.slice(1))
        const { section } = query
        if(section && section.length && areasModel[page]) {
            const areaIdx = areasModel[page].findIndex(i => i.id === section)
            setActiveArea(areaIdx > -1 ? areaIdx : 0)
        } else {
            setActiveArea(0)
        }
    }, [page, id])

    useEffect(() => {
        if(areasModel[page]) {
            const section = areasModel[page][activeArea] ? areasModel[page][activeArea].id : null
            if(section) {
                const queryStr = injectQueryPath('section', section)
                window.history.replaceState({}, null, `${window.location.pathname}${queryStr}`)
            }
        }
    }, [activeArea])

    useScrollPosition(({ prevPos, currPos }) => {
        const pos = Math.abs(currPos.y)
        setStackScrollClass(pos > 100 ? 'scrolled' : null)
    })

    const model = areasModel[page]

    const suspendArea = idx => {
        setActiveArea(-1)
        setTimeout(() => {
            setActiveArea(idx)
        }, 10)
    }

    return  <div
                className={`stack _${page} ${stackScrollClass ? '_'+stackScrollClass : ''}`}
            >
                <div
                    className='area-switch'
                    style={{backgroundColor: `var(--top-search-accent)`}}
                >
                    <TabMenu
                        model={model}
                        activeIndex={activeArea}
                        onTabChange={e => suspendArea(e.index)}
                    />
                </div>

                <AnimatePresence
                    mode='popLayout'
                >
                    {activeArea === -1 ? (
                        <motion.div
                            className={`stack-content stashed-stack`}
                            initial={{opacity: 0, height: 200}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            //dangerouslySetInnerHTML={{__html: stackStash}}
                        >
                            <Loader width={200} />
                        </motion.div>
                    ) : null}
                </AnimatePresence>

                <AnimatePresence
                    mode='wait'
                >
                    {activeArea > -1 ? (
                        <motion.div
                            id='stackSlot'
                            className={`stack-content ${page}-stack`}
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                        >
                            {activeArea > -1 && model[activeArea] ? <Page pathname={ctx.pathname} appUser={ctx.appUser} setActiveArea={setActiveArea} showAccount={() => ctx.setAccountOpen(true)} stackType={model[activeArea].id} /> : null}
                        </motion.div>
                    ) : null}
                </AnimatePresence>
            </div>
}

export default React.memo(Stack)

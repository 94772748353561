import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import './style.scss'

const keys = {
    pld: 'И',
    mvp: 'MVP',
    gs: 'Г',
    a: 'П',
    ga: 'Г+П',
    yc: 'ЖК',
    rc: 'КК'
}

const RankingsTable = ({ data, withTeams, withPos, pathname }) => {
    const [sort, setSort] = useState('pld')

    const history = useHistory()

    const sorted = data ? data.sort((a, b) => b.stats[sort] - a.stats[sort]) : null

    return  <div className='rankings-table'>
                <div className='row head'>
                    {withPos ? (<div className='pos'></div>) : null}
                    <div className='player'></div>
                    <div className='values'>
                        {Object.entries(keys).map((k, i) => (
                            <div
                                className={'alterfont'+(sort === k[0] ? ' sort' : '')}
                                key={i}
                                onClick={() => setSort(k[0])}
                            ><span>{k[1]}</span></div>
                        ))}
                    </div>
                </div>

                {sorted ? sorted.map(row => (
                    <div className='row' key={row._id}>
                        {withPos ? (<div className='pos'>{row.pos}</div>) : null}
                        <div className='player' onClick={() => history.push(`${pathname}/player/${row._id}`)}>
                            <div className='avatar'></div>
                            <div className='name'>{row.name} {row.surname}</div>
                        </div>
                        <div className='values'>
                            {Object.entries(keys).map((k, i) => (
                                <div className='alterfont' key={i}>{row.stats[k[0]]}</div>
                            ))}
                        </div>
                    </div>
                )) : null}
            </div>
}

export default RankingsTable

import React, { useState, useEffect } from 'react'

import { Skeleton } from 'primereact/skeleton'
import { Tag } from 'primereact/tag'

import { Lineup } from '../../Atoms'

import axios from 'axios'
import { ENDPOINT } from '../../../env'

import './style.scss'

const TeamLineup = ({ teamId }) => {
    const [data, setData] = useState(null)

    useEffect(() => {
        if(teamId) {
            axios.get(`${ENDPOINT}client/avglineup/${teamId}`)
                .then(resp => {
                    setData(resp.data)
                })
        }
    }, [teamId])

    return  <div className='team-lineup'>
                <div className='title alterfont'>
                    <span>Основа</span>
                    <Tag>по последним 10 матчам</Tag>
                </div>

                <div className='team-lineup_body'>
                    {!data ? (
                        <Skeleton width={320} height={270} />
                    ) : (
                        <Lineup
                            width={320}
                            data={data}
                            extended={true}
                            theme={{
                                numFill: 'var(--blue-600)',
                                numColor: 'var(--blue-100)',
                                numShadow: 'var(--blue-200)',
                                nameColor: 'var(--blue-50)'
                            }}
                        />
                    )}
                </div>
            </div>
}

export default TeamLineup

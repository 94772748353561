import React, {useState, useEffect, useContext} from 'react'
import Hotkeys from "react-hot-keys"

import {GlobalContext} from "../../../../../ctx";

import {postByUrl} from "../../../../../v3MethodsService";

import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';

import './style.scss'

const Error = ({ }) => {

    const [isModal, setIsModal] = useState(false)
    const [state, setState] = useState(null)
    const [isValid, setIsValid] = useState(false);

    const [global, setGlobal] = useContext(GlobalContext)
    const toast = global && global.toast ? global.toast : {}

    useEffect(() => {
        if (state) {
            const notValid = formData.map(f => !state[f.key]).filter(f => f)
            setIsValid(!notValid.length)
        } else {
            setIsValid(false)
        }
    }, [state]);

    const inputHandler = (val, key) => {
        setState(prev => ({...prev, [key]: val}))
    }

    const sendHandler = async () => {
        const data = {
            "fields": [
                { "name": "ФИО", "value": state.surname + " " + state.name + " " + state.patronymic },
                { "name": "email", "value": state.email },
                { "name": "Проблема", "value": state.problem }
            ], //что отправить
            "mail": "test@moder.ru", //кому отправить
            "title": "Ошибка регистрации" //заголовок для почты

        }
        postByUrl('feedback', data)
            .then(resp => {
                if (resp && resp.success) {
                    setIsModal(false)
                    toast.show({ severity: "success", life: 4000, summary: "Ваше обращение отправлено. С вами скоро свяжутся" });
                    setState(null)
                } else {
                    toast.show({ severity: "error", life: 4000, summary: "Ошибка отправки обращения" });
                }
            })
    }

    const onKeyDown = async (e) => {
        if ((e === 'Enter' || e.key === 'Enter')) {
            await sendHandler()
        }
    }

    return (
        <div className='auth2__container reg-error'>
            <Dialog visible={isModal} onHide={() => setIsModal(false)} header='Опишите проблему'>
                <Hotkeys keyName="Enter" onKeyDown={onKeyDown}>
                    <div className='reg-error__modal'>
                        <div className='reg-success-person-data__fields'>
                            {formData.map(f => (
                                <div key={f.key} className={`auth2__input-container auth2__input-container_${f.size}`}>
                                    <label htmlFor={f.key} className='auth2__input-container-label'>{f.label}</label>
                                    {renderField(state, f, inputHandler)}
                                </div>
                            ))}
                        </div>
                        <Button label='Отправить' onClick={sendHandler} className='auth2__button' disabled={!isValid}/>
                    </div>
                </Hotkeys>
            </Dialog>
            <div className='auth2__title'>
                Ошибка создания учетной записи
            </div>
            <div className='reg-success-kind__subtitle'>
                Ссылка, которой Вы воспользовались не существует или устарела. Пожалуйста, проверьте источник ссылки.
                Если ошибка повторяется - обратитесь в техническую поддержку
            </div>
            {/*<Button label='Написать в поддержку' onClick={() => setIsModal(true)} className='auth2__button'/>*/}
        </div>
    )
}

const formData = [
    {key: "surname", label: "Фамилия*", placeholder: "Укажите фамилию", type: "input", size: "half"},
    {key: "name", label: "Имя*", placeholder: "Укажите имя", type: "input", size: "half"},
    {key: "patronymic", label: "Отчество*", placeholder: "Укажите отчество", type: "input", size: "half"},
    {key: "email", label: "Почта*", placeholder: "Укажите почту", type: "input", size: "half"},
    {key: "problem", label: "Проблема*", placeholder: "Опишите подробнее", type: "textarea", size: "full"},
]

const renderField = (state, field, inputHandler) => {
    switch (field.type) {
        case 'input': return <InputText id={field.key} value={state?.[field.key] || ''} onChange={(e) => inputHandler(e.target.value, field.key)} placeholder={field.placeholder} className='auth2__input'/>
        case 'textarea': return <InputTextarea id={field.key} value={state?.[field.key] || ''} onChange={(e) => inputHandler(e.target.value, field.key)} placeholder={field.placeholder} className='auth2__input'/>
        default: return null
    }
}

export default Error
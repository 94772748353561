import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Tag } from 'primereact/tag'
import h337 from 'heatmap.js'

import './style.scss'

const Img = ({ src }) => {
    const [loaded, setLoaded] = useState(false)
    const [holder, setHolder] = useState(false)

    useEffect(() => {
        setLoaded(false)
        setHolder(false)
    }, [src])

    const imgRef = useRef()
    const handleErr = e => {
        setLoaded(true)
        setTimeout(() => {
            setHolder(true)
        }, 150)
        setHolder(true)
        e.target.src = require('../StatsCard/holder.png')
    }

    return  <img className={(loaded ? 'loaded' : '')+' '+(holder ? 'holder' : '')} ref={imgRef} src={src} onLoad={e => setLoaded(true)} onError={e => handleErr(e)} />
}

const renderLines = (data, theme, extended, history, highlightPerson, mvp, pathname) => {
    const model = data.formation ? data.formation.split('-') : []
    let pull = [...data.players]

    const rows = model.reduce((acc, qty) => {
        acc.push(pull.slice(0, qty))
        pull = pull.slice(qty)

        return acc
    }, []).reverse()

    return rows.map((row, i) => (
        <div className='lineup-row' key={i}>
            {row.map((p, i) => (
                <div className={'lineup-row-item'+(highlightPerson && highlightPerson === p._id ? ' highlight' : '')} key={i} onClick={() => history.push(`${pathname}/player/${p._id}`)}>
                    <div
                        className='lineup-row-item_num alterfont'
                        style={theme ? {
                            background: `var(--widget-lineup-slot)`,
                            color: `${theme.numColor}`,
                            boxShadow: `1px 1px 20px var(--widget-lineup-slot-shadow)`
                        } : {}}
                    >{extended && !highlightPerson ? (
                        <Img src={p.avatarUrl || ''} />
                    ) : !highlightPerson ? p.num : null}{highlightPerson && highlightPerson === p._id ? (<i className='pi pi-star'></i>) : null}{mvp && mvp === p._id ? (<Tag className='mvp'>MVP</Tag>) : null}{p.qty && p.qty > 1 ? <span className='qty'>{p.qty}</span> : null}</div>
                    <div
                        className='lineup-row-item_name alterfont'
                        style={{color: `var(--widget-lineup-name)`}}
                    >{p.surname}</div>
                    {extended && p.teams && p.teams[0] ? (
                        <div
                            className='lineup-row-item_team'
                            style={{
                                color: `var(--widget-lineup-team)`
                            }}
                        >{p.teams[0].name}</div>
                    ) : null}
                </div>
            ))}
        </div>
    ))
}

const Lineup = ({ width, data, theme, extended, heatmap, idx, highlightPerson, manager, mvp, pathname }) => {
    const heatRef = useRef()
    const luRef = useRef()

    useEffect(() => {
        if(data && heatmap && luRef && luRef.current) {
            const conf = {
                container: luRef.current,
                blur: .6,
                //opacity: .5,
                radius: 20,
                gradient: {
                    '.1': '#85b2f9',
                    '.3': '#76db9b',
                    '.5': '#fcc39b',
                    '.95': '#ff8780'
                }
            }

            heatRef.current = h337.create(conf)

            const rect = luRef.current.getBoundingClientRect()
            const { width, height } = rect

            const maxPoint = data.reduce((val, point) => {
                if(point.value > val) {
                    val = point.value
                }
                return val
            }, 0)

            heatRef.current.setData({min: 0, max: maxPoint, data: []})

            for(let point of data) {
                heatRef.current.addData({x: width*(point.x/100), y: height*(point.y/100), value: point.value})
            }
        }
    }, [data, luRef])

    const history = useHistory()

    return  <div
                id='lineupContainer'
                className={`lineup`}
                ref={luRef}
                style={{
                    width: width,
                    height: width*.85
                }}
            >
                {data && data.players && !heatmap ? renderLines(data, theme, extended, history, highlightPerson, mvp, pathname) : heatmap ? !data ? (<span className='empty'>нет данных</span>) : null : (<span className='empty'>нет расстановки</span>)}
                {manager && manager.length && !heatmap ? (<span className='manager alterfont'>менеджер: {manager}</span>) : null}
            </div>
}

export default Lineup

import { useCookies } from "react-cookie";

import {Button} from "primereact/button";

import "./style.scss";

const CookiesNotification = () => {
    const [cookies, setCookie] = useCookies(["_am_vmf_public_cookie"]);

    const onChange = (newName) => {
        const date = new Date();
        date.setFullYear(new Date().getFullYear() + 1);
        setCookie("_am_vmf_public_cookie", newName, { path: "/", expires: date });
    };

    return !cookies["_am_vmf_public_cookie"] ? (
        <div className={"cookies"}>
            <div className={"cookies-wrapper"}>
                <div className={"cookies-text"}>
                    Мы обрабатываем Cookies для улучшения работы сайта, анализа трафика, персонализации сервисов и удобства пользователей.
                    <div className={"cookies-text-secondary"}>
                        Используя сайт или кликая «Я согласен», Вы соглашаетесь с <span className="cookies__link" onClick={() => window.open('/docs/cookies.pdf')}>Условиями обработки метрических данных</span>, а также подтверждаете ознакомление с политикой обработки персональных данных. Вы можете запретить обработку Cookies в настройках браузера. Пожалуйста, ознакомьтесь с Условиями
                    </div>
                </div>
                <Button id="acceptCookies" onClick={() => onChange(true)} label='Я согласен' className='auth2__button' style={{width: "max-content"}}/>
            </div>
        </div>
    ) : null;
};

export default CookiesNotification;

import React, { useState, useEffect } from 'react'

import { StandingsSkeleton, NonIdealState } from '../../Atoms'
import { Dropdown } from 'primereact/dropdown'

import { RankingsTable } from '../../Atoms'

import './style.scss'

import axios from 'axios'
import { ENDPOINT } from '../../../env'

const Players = ({ tours, teamId, pathname }) => {
    const [data, setData] = useState({})
    const [selected, setSelected] = useState(null)
    const [loaded, setLoaded] = useState(null)
    const [teamSubject, setTeamSubject] = useState(teamId)
    const [fallbackedLoad, setFallbackedLoad] = useState(false)
    const [showErr, setShowErr] = useState(false)

    useEffect(() => {
        if(tours && tours[1]?.items) {
            setSelected(tours[1].items[0])
        } else if(tours && tours[0]) {
            setSelected(tours[0].items[0])
        }
    }, [tours])

    useEffect(() => {
        setShowErr(false)
        if(selected && !data[selected._id] && (selected._id !== loaded)) {
            loadSample()
        }
    }, [selected])

    useEffect(() => {
        if(teamId && (teamSubject !== teamId)) {
            setData({})
            setLoaded(null)
            setTeamSubject(teamId)
            if(tours && tours[1]?.items) {
                setSelected(tours[1].items[0])
            } else if(tours && tours[0]) {
                setSelected(tours[0].items[0])
            }
        }
    }, [teamId])

    useEffect(() => {
        console.log('TS upd')
        setTimeout(() => {
            if(selected) {
                loadSample()
            }
        }, 200)
    }, [teamSubject])

    const loadSample = () => {
        setShowErr(false)
        axios.get(`${ENDPOINT}client/playersstats/${teamSubject}?sample=${selected._id}`)
            .then(resp => {
                if(resp.data.error) {
                    setShowErr(true)
                } else {
                    setData({
                        ...data,
                        [selected._id]: resp.data.filter(i => i._id)
                    })

                    setLoaded(selected._id)
                }
            })
    }

    return  <div className='team-players'>
                <div className='top'>
                    <div className='title alterfont'>Статистика игроков</div>
                    <div className='select-tour'>
                        <Dropdown
                            className='compact'
                            value={selected}
                            optionLabel="label"
                            optionGroupLabel="label"
                            optionGroupChildren="items"
                            options={tours ? tours.filter(g => g && g.items && g.items.length) : []}
                            onChange={e => setSelected(e.value)}
                        />
                    </div>
                </div>

                <div className='body'>
                    {showErr ? (
                        <NonIdealState
                            icon='boots'
                            height={300}
                            color='var(--blue-900)'
                            iconColor='blue-300'
                            text='нет данных за выбранный период'
                        />
                    ) : selected ? data[selected._id] ? (
                        <RankingsTable
                            data={data[selected._id]}
                            withTeams={false}
                            withPos={false}
                            pathname={pathname}
                        />
                    ) : (
                        <StandingsSkeleton
                            rows={10}
                            compact={true}
                        />
                    ) : null}
                </div>
            </div>
}

export default Players

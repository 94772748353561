import React, { useEffect, useRef, useState } from "react";
import {useHistory} from "react-router-dom";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import moment from "moment/moment";
import defaultLogo from "../img/teamL.png";
import {Achievement} from "../../../Atoms";
import noImage from '../img/noImage.svg'
import "./style.scss";

const Slider = ({ data, type = false, unsubscribeHandler, onHide }) => {
    const history = useHistory()

    const getTitle = (type, value) => {
        switch (type) {
            case 'squads': return `Принял участие в ${value}-м матче`
            case 'goals': return `Забил ${value}-й гол`
            case 'mvps': return `Получил ${value}-й MVP`
        }
    }

    const createSplide = ({ data, type }) => {
        switch (type) {
            case "match": {
                return (
                    <div className="profileSlider-match">
                        <div className="profileSlider-match__top">
                            <img src={data.teamA?.logo} className="profileSlider-match__logo" />
                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
                                <path
                                    d="M6.30566 6L9.65723 2.64844C9.71452 2.59115 9.75928 2.52669 9.7915 2.45508C9.82373 2.38346 9.83984 2.30827 9.83984 2.22949C9.83984 2.07194 9.78434 1.93766 9.67334 1.82666C9.56234 1.71566 9.42806 1.66016 9.27051 1.66016C9.19173 1.66016 9.11654 1.67627 9.04492 1.7085C8.97331 1.74072 8.90885 1.78548 8.85156 1.84277L5.5 5.19434L2.14844 1.84277C2.09115 1.79264 2.03027 1.75325 1.96582 1.72461C1.90137 1.69596 1.82975 1.68164 1.75098 1.68164C1.59342 1.68164 1.45915 1.73714 1.34814 1.84814C1.23714 1.95915 1.18164 2.09342 1.18164 2.25098C1.18164 2.32975 1.19596 2.40137 1.22461 2.46582C1.25326 2.53027 1.29264 2.59115 1.34277 2.64844L4.69434 6L1.34277 9.35156C1.28548 9.40885 1.24251 9.47152 1.21387 9.53955C1.18522 9.60758 1.1709 9.68099 1.1709 9.75977C1.1709 9.83854 1.18522 9.91195 1.21387 9.97998C1.24251 10.048 1.28548 10.1071 1.34277 10.1572C1.3929 10.2145 1.45199 10.2575 1.52002 10.2861C1.58805 10.3148 1.66146 10.3291 1.74023 10.3291C1.81901 10.3291 1.89242 10.3148 1.96045 10.2861C2.02848 10.2575 2.09115 10.2145 2.14844 10.1572L5.5 6.80566L8.85156 10.1572C8.90885 10.2145 8.97152 10.2575 9.03955 10.2861C9.10758 10.3148 9.18099 10.3291 9.25977 10.3291C9.33854 10.3291 9.41195 10.3148 9.47998 10.2861C9.54801 10.2575 9.6071 10.2145 9.65723 10.1572C9.71452 10.1071 9.75749 10.048 9.78613 9.97998C9.81478 9.91195 9.8291 9.83854 9.8291 9.75977C9.8291 9.68099 9.81478 9.60758 9.78613 9.53955C9.75749 9.47152 9.71452 9.40885 9.65723 9.35156L6.30566 6Z"
                                    fill="#D0E1FD"
                                />
                            </svg>
                            <img src={data.teamB?.logo} className="profileSlider-match__logo" />
                        </div>
                        <span className="profileSlider-match__name">
                            {data.teamA?.name} <span style={{ color: "#85B2F9" }}> VS </span>
                            {data.teamB?.name}
                        </span>
                        {/* <span className="profileSlider-match__name"></span> */}
                        <div className="profileSlider-match__score">
                            {data.teamA?.score} : {data.teamB?.score}
                        </div>
                        <span className="profileSlider-match__place">{data.place}</span>
                        <span className="profileSlider-match__time">{data.time}</span>
                        <span className="profileSlider-match__time">{data.date}</span>
                        <span className="profileSlider-match__place">{data.league}</span>
                    </div>
                );
            }

            case "achieve": {
                return (
                    <div className="profileSlider-achieve">
                        <Achievement sample={data.sampleType} scope={data.scope} value={data.grade?.value} className="profileSlider-achieve__img"/>
                        {/*<img src={data.img}  />*/}
                        <div className="profileSlider-achieve__card">
                            <span className="profileSlider-achieve__title">{getTitle(data.scope, data.grade?.value)}</span>
                            <span className="profileSlider-achieve__subtitle">{(data.tournament && data.tournament.name) || (data.team && data.team.name) || ''}</span>
                        </div>
                        <div className="profileSlider-achieve__date">{moment(data.date, 'YY-MM-DD').format('DD MMMM YYYY')}</div>
                    </div>
                );
            }

            case "teams":
                return (
                    <div className="profileSlider-teams">
                        {data.map((item, idx) => (
                            <div className="profileSlider-teams__item" key={idx}  onClick={() => {history.push(`/club/${item.clubId}`); onHide()}}>
                                <img src={item.club && item.club.emblem ? item.club?.emblem : item.emblem ? item.emblem : defaultLogo} className="profileSlider-teams__logo" />
                                <div className="profileSlider-teams__container">
                                    <span className="profileSlider-teams__title">{item.club ? item.club?.name : item.name}</span>
                                    <span className="profileSlider-teams__subtitle">{item.city}</span>
                                </div>
                                {type !== "teams" ? <span className="profileSlider-teams__unsubscribe" onClick={() => unsubscribeHandler(item._id)}>Отписаться</span> : null}
                                <i className="pi pi-angle-right"/>
                            </div>
                        ))}
                    </div>
                );

            case "favorite":
                return (
                    <div className="profileSlider-teams">
                        {data.map((item, idx) => (
                            <div className="profileSlider-teams__item" key={idx} onClick={() => {history.push(item.url); onHide()}}>
                                <img src={item.img ? item.img : item.type === 'player' ? noImage : defaultLogo} className="profileSlider-teams__logo" /> 
                                <div className="profileSlider-teams__container">
                                    <span className="profileSlider-teams__title">{item.info}</span>
                                    <span className="profileSlider-teams__subtitle">{item.city}</span>
                                </div>
                                {type !== "teams" ? <span className="profileSlider-teams__unsubscribe" onClick={() => unsubscribeHandler(item._id)}>Отписаться</span> : null}
                                <i className="pi pi-angle-right"/>
                            </div>
                        ))}
                    </div>
                );

            default: {
                return (
                    <div className="profileSlider-news">
                        <img src={data.img} className="profileSlider-news__img" />
                        <div className="profileSlider-news__container">
                            <span className="profileSlider-news__title">{data.title}</span>
                            <span className="profileSlider-news__subtitle">{data.subtitle}</span>
                        </div>
                    </div>
                );
            }
        }
    };

    return (
        <div className={`profileSlider ${type}`}>
            <Splide
                options={{
                    pagination: true,
                    gap: "10px",
                    arrows: false,
                    perPage: ["achieve", "match"].includes(type) ? 3 : 1,
                    start: 0,
                }}
            >
                {data.map((item, idx) => (
                    <SplideSlide key={idx}>{createSplide({ data: item, type: type })}</SplideSlide>
                ))}
            </Splide>
        </div>
    );
};

export default Slider;

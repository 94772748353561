import React from 'react'
import { useHistory } from 'react-router-dom'

import './item.scss'

import moment from 'moment'

const Media = ({ data, item }) => {
    const isEmpty = !data || !data.length
    const history = useHistory()

    const getPhotoSrc = obj => {
        if(obj.sizes && obj.sizes.length) {
            return obj.sizes.sort((a, b) => b.height - a.height)[0].url
        } else {
            return ''
        }
    }

    const getVideoSrc = obj => {
        if(obj.image && obj.image.length) {
            return obj.image.sort((a, b) => b.height - a.height)[0].url
        } else {
            return ''
        }
    }

    return  <div
                onClick={() => history.push(`${window.location.pathname}?publication=${item}`)}
                className={'mb-item_media'+(isEmpty ? ' empty' : '')}
                style={!isEmpty ? {
                    backgroundImage: `url(${data[0].type === 'video' ? getVideoSrc(data[0].video) : getPhotoSrc(data[0].photo)}`
                } : {}}
            >
                {!isEmpty ? (
                    <img src={data[0].type === 'video' ? getVideoSrc(data[0].video) : getPhotoSrc(data[0].photo)} />
                ) : null}

                {!isEmpty && data[0].type === 'video' ? (
                    <div className='play_btn'></div>
                ) : null}
            </div>
}

const Item = ({ data }) => {
    const history = useHistory()

    return  <div className='mb-item'>
                <Media data={data.attachments} item={data._id} />

                <div className='title alterfont'>
                    <span className='date'>{moment(data.date, 'YY-MM-DD').format('DD MMMM YYYY')}</span>
                    <div
                        className='text'
                        onClick={() => history.push(`${window.location.pathname}?publication=${data._id}`)}
                    >{data.title}</div>
                </div>
            </div>
}

export default Item

import React, {useEffect, useRef, useState} from "react"

import moment from "moment";

import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Password} from "primereact/password";
import {Calendar} from "primereact/calendar";
import {InputMask} from "primereact/inputmask";
import {Dropdown} from "primereact/dropdown";
import { addLocale } from 'primereact/api';

import './style.scss'

const SideProfileEditor = ({ state, changeState, formData, showInvalid }) => {

    return (
        <div className='side-profile-editor'>
            <div className='side-profile-editor__title'>Данные профиля</div>
            <div className='side-profile-editor__fields'>
                {formData.map(f => (
                    <div key={f.key} className={`side-profile__input-container side-profile__input-container_${f.size}`}>     
                        <label htmlFor={f.key} className='side-profile__input-container-label'>{f.label}{f.icon ?<i className={f.icon}></i> : null}</label>
                        {renderField(state, f, changeState, showInvalid)}
                    </div>
                ))}
            </div>
        </div>
    )
}

const renderField = (state, field, inputHandler, showInvalid) => {
    switch (field.type) {
        case 'input': return <InputText id={field.key} value={state?.[field.key]?.trim() || ''} onChange={(e) => inputHandler(e.target.value, field.key === 'email' ? 'creds' : 'profile', field.key)} placeholder={field.placeholder} className={`side-profile__input ${showInvalid && !state?.[field.key] ? ' side-profile__input_invalid' : ''}`}/>
        case 'password': return <Password id={field.key} value={state?.[field.key] || ''} onChange={(e) => inputHandler(e.target.value, field.key)} placeholder={field.placeholder} className={`side-profile__password${showInvalid ? ' side-profile__password_invalid' : ''}`} toggleMask feedback={false}/>
        case 'calendar': return <Calendar id={field.key} value={state?.[field.key] ? moment(state?.birthday, 'DD.MM.YYYY').toDate() : ''} onChange={(e) => inputHandler(e.target.value ? moment(e.target.value).format('DD.MM.YYYY') : e.target.value, 'profile', field.key)} placeholder={field.placeholder} className='side-profile__calendar' showIcon locale='ru' dateFormat='dd.mm.yy' maxDate={new Date()} mask='99.99.9999' appendTo={"self"}/>
        case 'mask': return <InputMask id={field.key} value={state?.[field.key] || ''} onChange={(e) => inputHandler(e.target.value, 'profile', field.key)} placeholder={field.placeholder} className={`side-profile__input ${showInvalid && !state?.[field.key] && field.key !== 'phone' ? ' side-profile__input_invalid' : ''}`} mask={field.mask}/>
        case 'dropdown': return <Dropdown id={field.key} value={state?.[field.key] || ''} options={field.options} onChange={(e) => inputHandler(e.target.value, field.key)} placeholder={field.placeholder} className='side-profile__input' appendTo={"self"}/>
        default: return null
    }
}

addLocale("ru", {
    firstDayOfWeek: 1,
    dayNames: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Субота"],
    dayNamesMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
    monthNames: ["январь", "февраль", "март", "апрель", "май", "июнь", "июль", "август", "сентябрь", "октябрь", "ноябрь", "декабрь"],
    monthNamesShort: ["янв", "фев", "мар", "апр", "май", "июн", "июл", "авг", "сен", "окт", "ноя", "дек"],
    today: "Сегодня",
    clear: "Очистить",
});

export default SideProfileEditor
import React, { useState, useContext, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { ProgressSpinner } from 'primereact/progressspinner'

import VideoItem from '../Publication/VideoItem'
import AlbumItem from '../Publication/AlbumItem'

import DesktopContext from '../../Layouts/Desktop/ctx'
import { injectQueryPath } from '../../utils'

import moment from 'moment'

const Item = ({ data, theme, layout }) => {
    const [previewLoaded, setPreviewLoaded] = useState(false)
    const history = useHistory()

    const ctx = useContext(DesktopContext)
    const previewRef = useRef()

    useEffect(() => {
        setPreviewLoaded(false)
        const img = new Image()
        img.onload = () => {
            if(previewRef.current) {
                previewRef.current.style.backgroundImage = 'url(' + img.src + ')'
                setTimeout(() => {
                    setPreviewLoaded(true)
                }, 500)
            }
        }

        img.src = (data.attachments[0].album && data.attachments[0].album.thumb.sizes[0].url) || data.landscapeCover || data.portraitCover
    }, [data._id])

    return  <div
                className={`item${!previewLoaded ? ' unloaded' : ''}`}
                ref={previewRef}
                style={{
                    //backgroundImage: `url(${layout === 'mobile' ? data.portraitCover : data.landscapeCover})`,
                    boxShadow: layout !== 'mobile' ? `2px 2px 25px ${theme.previewShadowColor}` : 'none'
                }}
            >
                {layout !== 'mobile' ? (
                    <ProgressSpinner />
                ) : null}

                {layout === 'mobile' ? <div className='item-content'>
                    <div
                        className='item-content_title'
                        style={{color: `var(--${theme.titleColor})`}}
                    >{data.title}</div>
                    <div
                        className='item-content_date'
                        style={{color: `var(--${theme.metaColor})`}}
                    >{moment(data.date+' '+data.time, 'YY-MM-DD HH:mm').format('dddd, DD MMMM, HH:mm')}</div>
                </div> : data.type === 'video' ? (
                    <div className='item-load_container'>
                        <VideoItem
                            pubId={data._id}
                            idx={0}
                            isDesktop
                        />
                    </div>
                ) : data.type === 'album' ? (
                    <div className='item-load_container'>
                        <AlbumItem
                            isDesktop
                            gallery={data.attachments || []}
                        />
                    </div>
                ) : data.type === 'mixed' || data.type === 'text' ? (
                    <div
                        className='click-cover'
                        onClick={() => {
                            history.push(`${window.location.pathname}${injectQueryPath('publication', data._id)}`)
                            if(ctx && ctx.setPublicationId) {
                                ctx.setPublicationId(data._id)
                            }
                        }}
                    >
                    </div>
                ) : null}
            </div>
}

export default Item

import React, { useState, useEffect } from 'react'
import { Route, Switch, useHistory } from "react-router-dom"

import LayoutContext from './ctx'
import qs from 'qs'

import withStorage from '../Mobile/Storage'

import { injectQueryPath } from '../../utils'

import { Top, MediaBlock, MediaBlockV2, MatchesCarousel, Tournament, Feed, Standings, StatsBlock, Highlights, BottomStories, Footer, Match, Club, Player } from '../../Components'
import { TopLineup, AchievementsCarousel, BannerNode, ScrollToTop } from '../../Components/Atoms'
import MultiWrap from '../../Components/Standings/MultiWrap'
import DesktopPub from '../../Components/Publication/Desktop'
import SideProfile from "../../Components/v2/SideProfile";

import './style.scss'
import './pixel-ratios.scss'
import './medias.scss'

const Desktop = ({ width, config, federation, pathname }) => {
    const query = qs.parse(window.location.search.slice(1))

    const [toursArr, setToursArr] = useState(null)
    const [user, setUser] = useState(null)
    const [accountOpen, setAccountOpen] = useState(false)
    const [publicationId, setPublicationId] = useState(query.publication || null)

    const [bannerNode, setBannerNode] = useState(config.bannerNode ? (
        <BannerNode
            media={config.bannerNode.media}
            action={() => {
                if(config.bannerNode.publicationId) {
                    setPublicationId('6412c6f4f71656203756a69d');
                    history.push(`${window.location.pathname}${injectQueryPath('publication', config.bannerNode.publicationId)}`);
                } else {
                    window.open(config.bannerNode.externalLink)
                }
            }}
        />) : null
    )

    const history = useHistory()

    return  <LayoutContext.Provider
                value={{appUser: user, setUser, accountOpen, setAccountOpen, federation, setPublicationId, bannerNode}}
            >
            <div className='layout-desktop'>
                <Top
                    CONF={pathname.length ?
                        {
                            ...config.top,
                            topBanners: config.topBanners,
                            topLinks: config.topLinks,
                            brandEmblem: config.brandEmblem,
                            federation,
                            nav: config.app.nav
                        }
                        : {...config, nav: config.app.nav, federation}
                    }
                    width={width}
                    layout={'desktop'}
                    pathname={pathname}
                />
                <ScrollToTop />
                <Switch>
                    <Route path={`${pathname}/match/:id`}>
                        <Match {...config.match} width={width} layout='desktop' pathname={pathname} fantasy={config.fantasy} />
                    </Route>
                    <Route path={`${pathname}/tournament/:id`}>
                        <MatchesCarousel {...config.matchesCarousel} pathname={pathname} width={width} scope='tournament' layout='desktop' extendedMode={true} />
                        <MediaBlock {...config.mediablock} pathname={pathname} width={width} layout='desktop' subjectId={null} scope='tournament' />
                        <Tournament {...config.tournament} pathname={pathname} width={width} layout='desktop' />
                        <StatsBlock {...config.stats} pathname={pathname} blured={false} width={width} layout='desktop' />
                    </Route>

                    <Route path={`${pathname}/club/:id`}>
                        <Club {...config} pathname={pathname} />
                    </Route>

                    <Route path={`${pathname}/player/:id`}>
                        <Player pathname={pathname} />
                    </Route>

                    <Route exact path={`${pathname}/`}>
                        <MediaBlock
                            {...config.mediablock}
                            width={width}
                            layout='desktop'
                            subjectId={federation._id}
                            scope='federation'
                        />
                        <MatchesCarousel {...config.matchesCarousel} pathname={pathname} subject={federation._id} scope='federation' width={width} layout='desktop' extendedMode={true} />
                        <div
                            className='desktop-section'
                            style={{backgroundColor: config.feed.theme.surface.includes('#') ? config.feed.theme.surface : `var(--${config.feed.theme.surface})`}}
                        >
                            <div className='section-stripe' style={{background: config.feed.theme.stripe.includes('#') ? config.feed.theme.stripe : `var(--${config.feed.theme.stripe})`}}></div>
                            <div className='container'>
                                <div className='primary'>
                                    <div className='sub-cols'>
                                        {bannerNode}

                                        <div className='main'>
                                            <Feed subjectId={federation._id} {...config.feed} width={width} layout='desktop' />
                                        </div>

                                        <div className='secondary'>
                                            <TopLineup scope='federation' pathname={pathname} subjectId={federation._id} setToursArr={setToursArr} />
                                            <AchievementsCarousel toursArr={toursArr} pathname={pathname} scope='federation' subjectId={federation._id} />
                                        </div>
                                    </div>
                                </div>
                                <div className='secondary'>
                                    <MultiWrap {...config.standings} width={width} layout='desktop' pathname={pathname} />
                                </div>
                            </div>
                        </div>
                        <StatsBlock {...config.stats} pathname={pathname} blured={true} width={width} layout='desktop' />
                        <Highlights {...config.highlights} width={width} scope='federation' subjectId={federation._id} layout='desktop' contentType='video' />
                        {/*<BottomStories {...config.stories} width={width} layout='desktop' />*/}
                    </Route>
                </Switch>

                <Footer {...config.footer} width={width} layout='desktop' />

                <DesktopPub
                    publication={publicationId}
                    resetPublication={() => setPublicationId(null)}
                    query={query}
                />
            </div>
        </LayoutContext.Provider>
}

export default withStorage(Desktop)

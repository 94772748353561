import React, { useEffect, useContext, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { GlobalContext } from "../../../ctx";

import { Toast } from "primereact/toast";

import Login from "./Login";
import Reg from "./Reg";
import Recover from "./Recover";

import "./style.scss";

const Auth2 = ({ onHide }) => {
    const [global, setGlobal] = useContext(GlobalContext);
    const history = useHistory();
    const toast = useRef(null);
    const location = useLocation();
    const path = location.search.replace("?", "").split("&")[0];

    useEffect(() => {
        setGlobal((prev) => ({ ...prev, toast: toast.current }));
    }, []);

    const updateGlobal = (val, key) => {
        setGlobal((prev) => ({ ...prev, [key]: val }));
    };

    const Specified = wrap[path] ? wrap[path] : wrap.login;

    return (
        <div className="auth2">
            <Toast ref={toast} position="top-right" />
            <i
                className="pi pi-times"
                onClick={() => {
                    onHide();
                    history.replace(location.pathname);
                }}
            ></i>
            <Specified updateGlobal={updateGlobal} />
        </div>
    );
};

const wrap = {
    login: Login,
    reg: Reg,
    recover: Recover,
};

export default Auth2;

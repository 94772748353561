import React, { useState, useEffect, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ContentTabs, Emblem, BtnSet, Loader, PublicationPreview, VideoBox } from '../Atoms'
import Info from './Info'
import Events from './Events'
import Rosters from './Rosters'
import Stats from './Stats'

import { injectQueryPath } from '../../utils'

import axios from 'axios'
import { ENDPOINT } from '../../env'
import moment from 'moment'

import Lightbox from 'yet-another-react-lightbox'

import 'yet-another-react-lightbox/styles.css'
import './style.scss'

import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import 'yet-another-react-lightbox/plugins/thumbnails.css'

import DesktopContext from '../../Layouts/Desktop/ctx'
import {CONF} from "../../configs";

const tabs = [
    {label: 'Инфо', id: 'info', icon: 'pi pi-info-circle', component: Info},
    {label: 'События', id: 'events', icon: 'pi pi-bolt', component: Events},
    {label: 'Составы', id: 'rosters', icon: 'pi pi-list', component: Rosters},
    {label: 'Статистика', id: 'stats', icon: 'pi pi-chart', component: Stats}
]

const transformName = str => {
    if(!str.includes(' ') && !str.includes('-') && str.length > 12) {
        return str.slice(0, str.length-5)+' '+str.slice(str.length-5)
    } else {
        return str
    }
}

const Match = ({ theme, width, layout, pathname, fantasy }) => {
    const [data, setData] = useState(null)
    const [tab, setTab] = useState(0)
    const [videoBoxItem, setVideoBoxItem] = useState(null)
    const [gallery, setGallery] = useState(null)
    const [galleryIndex, setGalleryIndex] = useState(-1)
    const [useProxy, setUseProxy] = useState(false)
    const [proxyData, setProxyData] = useState(null)
    const [statsData, setStatsData] = useState(null)

    const { id } = useParams()
    const history = useHistory()

    const ctx = useContext(DesktopContext)

    useEffect(() => {
        if(id) {
            axios.get(`${ENDPOINT}client/matchinfo/${id}?full=true`)
                .then(resp => {
                    setData(resp.data)
                    if(resp.data) {
                        if(resp.data.scores && resp.data.scores.full) {
                            setTab(1)
                            if(resp.data.statsData && resp.data.statsData.length) {
                                setStatsData(resp.data.statsData)
                            }
                        } else {
                            if(resp.data.matchday && resp.data.matchday.originDate === moment().format('YY-MM-DD') && !useProxy) {
                                setUseProxy(true)
                            }
                        }
                    }
                })
        }
    }, [id])

    useEffect(() => {
        if(useProxy) {
            const proxyInterval = setInterval(() => {
                axios.get(`${ENDPOINT}client/stateproxy/${id}`)
                    .then(resp => {
                        if(resp.data) {
                            setProxyData(resp.data)
                            if(resp.data.statsData && resp.data.statsData.length) {
                                setStatsData(resp.data.statsData)
                            }
                        }
                    })
            }, 10000)

            return () => clearInterval(proxyInterval)
        }
    }, [useProxy])

    const Specified = tabs[tab].component

    const scoreNode = data ? (
        <div className='match-main_score'>
            {proxyData && proxyData.time ? (
                <div className='state-indicator'>
                    <i></i>
                    <span>{proxyData.finished ? 'завершён' : !proxyData.time.active ? 'перерыв' : `идёт ${proxyData.time.displayMinute} минута`}</span>
                </div>
            ) : null}
            <div
                className='match-main_score_cell alterfont'
            >{data.scores && data.scores.full ? data.scores.full.home : proxyData && proxyData.time && proxyData.score ? proxyData.score.home : '-'}</div>
            <div
                className='match-main_score_cell alterfont'
            >{data.scores && data.scores.full ? data.scores.full.away : proxyData && proxyData.time && proxyData.score ? proxyData.score.away : '-'}</div>

            {data.scores && data.scores.shootout && data.scores.shootout.home ? (
                <div className='shootout'>{`по пенальти: ${data.scores.shootout.home}:${data.scores.shootout.away}`}</div>
            ) : null}
        </div>
    ) : null

    const homeTeamNode = data ? (
        <div className='match-main_team __home' onClick={() => history.push(`${pathname}/club/${data.home.club._id}?team=${data.home._id}`)}>
            <Emblem backdroped={true} size='md' source={data.home.emblem || data.home.club.emblem} />
            <div className='match-team_name alterfont'>{transformName(data.home.name)}</div>
        </div>
    ) : null

    const awayTeamNode = data ? (
        <div className='match-main_team __away' onClick={() => history.push(`${pathname}/club/${data.away.club._id}?team=${data.away._id}`)}>
            <Emblem backdroped={true} size='md' source={data.away.emblem || data.away.club.emblem} />
            <div className='match-team_name alterfont'>{transformName(data.away.name)}</div>
        </div>
    ) : null

    return  data ? <div
                className={`match ${layout}`}
                style={{
                    //width: width,
                    backgroundColor: theme.surface.includes('#') ? theme.surface : `var(--${theme.surface})`
                }}
            >
                <Helmet>
                    <title>{`Матч ${data.home.name} 🆚 ${data.away.name} | ${data.matchday.date} | ${CONF.appTitle}`}</title>
                </Helmet>

                <div className='container'>
                    <div className='primary'>
                        {layout === 'mobile' ? (
                            <div className='match-main'>
                                {scoreNode}

                                <div className='match-main_teams'>
                                    {homeTeamNode}
                                    {awayTeamNode}
                                </div>

                                <ContentTabs
                                    width={layout === 'mobile' ? width : '100%'}
                                    layout={layout}
                                    theme='blue'
                                    model={tabs}
                                    activeIdx={tab}
                                    onChange={e => setTab(e.index)}
                                />
                            </div>
                        ) : (
                            <div className='match-main'>
                                <div>
                                    <div className='match-main_teams'>{homeTeamNode}</div>
                                    {scoreNode}
                                    <div className='match-main_teams'>{awayTeamNode}</div>
                                </div>

                                {ctx.bannerNode}

                                <div className='match-btn_set'>
                                    <BtnSet
                                        theme='bluegray-50'
                                        darken='bluegray-400'
                                        size='sm'
                                        btns={statsData ? [
                                            {id: 'info', label: 'Информация', icon: 'info-circle'},
                                            {id: 'events', label: 'События', icon: 'bolt'},
                                            {id: 'rosters', label: 'Составы', icon: 'list'},
                                            {id: 'stats', label: 'Статистика', icon: 'chart'}
                                        ] : [
                                            {id: 'info', label: 'Информация', icon: 'info-circle'},
                                            {id: 'events', label: 'События', icon: 'bolt'},
                                            {id: 'rosters', label: 'Составы', icon: 'list'}
                                        ]}
                                        active={tabs[tab].id}
                                        onChange={id => setTab(tabs.findIndex(t => t.id === id))}
                                    />
                                </div>
                            </div>
                        )}

                        <div className='match-content'>
                            <Specified data={data} matchId={id} proxyData={proxyData} statsData={statsData} pathname={pathname} fantasy={fantasy} />
                        </div>
                    </div>

                    <div className='secondary'>
                        {data.publications ? data.publications.map(pub => (
                            <div className='match-publication' key={pub._id}>
                                <PublicationPreview
                                    data={pub}
                                    onOpen={data => {
                                        if(pub.mediaType === 'video') {
                                            setVideoBoxItem(pub)
                                        } else if (pub.mediaType === 'album') {
                                            setGallery(data)
                                            setGalleryIndex(0)
                                        } else {
                                            history.push(`${window.location.pathname}${injectQueryPath('publication', pub._id)}`)
                                            ctx.setPublicationId(pub._id)
                                        }
                                    }}
                                />
                            </div>
                        )) : null}
                    </div>

                    <VideoBox
                        data={videoBoxItem}
                        key={videoBoxItem ? videoBoxItem._id : null}
                        onClose={() => setVideoBoxItem(null)}
                    />

                    <Lightbox
                        slides={gallery}
                        open={galleryIndex >= 0}
                        index={galleryIndex}
                        close={() => {
                            setGalleryIndex(-1)
                            setGallery(null)
                        }}
                        plugins={[Thumbnails, Zoom]}
                    />
                </div>
            </div> : (
                <div className='match-loader'>
                    <Loader width={180} />
                </div>
            )
}

export default Match

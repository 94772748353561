import React, { useState, useEffect, useRef, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { Helmet } from 'react-helmet'

import './style.scss'

import Profile from './Profile'
import Summary from './Summary'
import PositionMap from './PositionMap'
import Feed from './Feed'

import { ENDPOINT } from '../../env'
import axios from 'axios'

import DesktopContext from '../../Layouts/Desktop/ctx'

const Player = ({ pathname }) => {
    const [player, setPlayer] = useState({})
    const [stiched, setStiched] = useState(null)
    const [selectedTeam, setSelectedTeam] = useState(null)
    const { id } = useParams()

    const ctx = useContext(DesktopContext)

    useEffect(() => {
        if(id) {
            setPlayer({})
            axios.get(`${ENDPOINT}client/playerCareer/${id}`)
                .then(resp => {
                    setPlayer(resp.data)
                })
        }
    }, [id])

    const feedRef = useRef()

    useScrollPosition(({ prevPos, currPos }) => {
        const pos = Math.abs(currPos.y)
        const feedEl = feedRef.current.offsetTop + 40

        const stichTarget = (feedRef.current.clientHeight - pos) < 400 ? 'bottom' : 'top'

        if((!stiched || (stiched !== stichTarget)) && (pos > feedEl)) {
            setStiched(stichTarget)
        }

        if(stiched && (pos < feedEl)) {
            setStiched(null)
        }
    })

    return  <div className="player filled-page">
                {player ? (
                    <Helmet>
                        {player.name ? (
                            <title>{`${player.name} ${player.surname} | Статистика и достижения игрока`}</title>
                        ) : (
                            <title>Статистика и достижения игрока</title>
                        )}
                    </Helmet>
                ) : null}

                <div className={`container${stiched ? ' stiched stiched-'+stiched : ''}`}>
                    <div className='col'>
                        <Profile {...player} selectedTeam={selectedTeam} setSelectedTeam={setSelectedTeam} />
                    </div>

                    <div className='content'>
                        <div className='focus'>
                            <div className='carousel'>
                                {ctx.bannerNode}
                            </div>
                            <div ref={feedRef}>
                                <Feed playerId={id} pathname={pathname} />
                            </div>
                        </div>

                        <div className='side'>
                            <div>
                                <Summary data={player && player.career ? player.career.career : null} team={selectedTeam} />
                                <PositionMap id={id} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
}

export default Player
